import { getToken } from '@/utils/auth'

export const status = [
  {
    id: 1,
    name: '启用'
  },
  {
    id: 0,
    name: '停用'
  }
]
export const processStatus = [
  {
    id: 1,
    name: '启用'
  },
  {
    id: 0,
    name: '禁用'
  }
]

export const bindStatus = [
  {
    id: 1,
    name: '已绑定'
  },
  {
    id: 0,
    name: '未绑定'
  }
]

export const wareHouseType = [
  {
    id: 0,
    name: '仓库'
  },
  {
    id: 1,
    name: '库区'
  },
  {
    id: 2,
    name: '库位'
  }
]

export const checkType = [
  {
    id: 0,
    name: '车间巡检'
  },
  {
    id: 1,
    name: '员工自检'
  },
  {
    id: 2,
    name: '过程首检'
  },
  {
    id: 3,
    name: '班组检查'
  },
  {
    id: 4,
    name: '来料检'
  },
  {
    id: 5,
    name: '成品检验'
  }
]

export const inType = [
  {
    id: 0,
    name: '采购入库'
  },
  {
    id: 1,
    name: '生产入库'
  },
  {
    id: 2,
    name: '包装入库'
  },
  {
    id: 3,
    name: '托盘入库'
  },
  {
    id: 4,
    name: '其他入库'
  },
  {
    id: 5,
    name: '撤回报工'
  }
]

export const outType = [
  {
    id: 0,
    name: '生产领料'
  },
  {
    id: 1,
    name: '销售出库'
  },
  {
    id: 2,
    name: '其他出库'
  },
  {
    id: 3,
    name: '生产投料'
  }
]

// 参数类型 0:数值 1:文本 2:单选 3:多选 4:附件 5:时间
export const paramsType = [
  {
    id: 0,
    name: '数值',
    template: {
      tagName: 'el-input-number',
      rules: [],
      props: {
        controlsPosition: 'right'
      },
      children: []
    }
  },
  {
    id: 1,
    name: '文本',
    template: {
      tagName: 'el-input',
      props: {},
      children: []
    }
  },
  {
    id: 2,
    name: '单选',
    template: {
      tagName: 'el-select',
      props: {},
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'label',
            value: 'value'
          }
        }
      ]
    }
  },
  {
    id: 3,
    name: '多选',
    template: {
      tagName: 'el-select',
      props: {
        multiple: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'label',
            value: 'value'
          }
        }
      ]
    }
  },
  {
    id: 4,
    name: '图片',
    template: {
      tagName: 'el-upload',
      props: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        listType: 'picture-card'
      },
      rules: [],
      children: [{
        tagName: 'i',
        domProps: { className: 'el-icon-plus' },
        children: []
      }]
    }
  },
  {
    id: 5,
    name: '时间',
    template: {
      tagName: 'el-date-picker',
      props: {
        type: 'datetime'
      },
      rules: [],
      children: []
    }
  }
]
// 订单来源
export const orderSource = [
  {
    id: 0,
    name: '新增'
  },
  {
    id: 1,
    name: '销售订单'
  },
  {
    id: 2,
    name: 'ERP'
  }
]
// 订单状态
export const orderStatus = [
  {
    id: 0,
    name: '未计划'
  },
  {
    id: 3,
    name: '部分计划'
  },
  {
    id: 1,
    name: '全部计划未完工'
  },
  {
    id: 2,
    name: '已完工'
  }
]
// 图纸状态
export const pageStatus = [
  {
    id: 0,
    name: '未确认',
    color: '#FA6400'
  },
  {
    id: 1,
    name: '已确认',
    color: '#9FA1B7'
  }
]
// 优先级
export const priorityStatus = [
  {
    id: 0,
    name: '普通'
  },
  {
    id: 1,
    name: '紧急'
  }
]

// 工序类别  0:自制工序 1:外协工序 2:自制和外协工序
export const procedureType = [
  {
    id: 0,
    name: '自制工序'
  },
  {
    id: 1,
    name: '外协工序'
  }
  // {
  //   id: 2,
  //   name: '自制和外协工序'
  // }
]
// 计划来源
export const planSource = [
  {
    id: 0,
    name: '新增'
  },
  {
    id: 1,
    name: '生产订单'
  },
  {
    id: 2,
    name: 'ERP'
  }
]
// 计划状态
export const planStatus = [
  {
    id: 0,
    name: '未排程未下发'
  },
  {
    id: 1,
    name: '未排程已下发'
  },
  {
    id: 2,
    name: '已排程未下发'
  },
  {
    id: 3,
    name: '已排程已下发'
  },
  {
    id: 4,
    name: '未排程已完成'
  },
  {
    id: 5,
    name: '已排程已完成'
  }
]
// 计划-备料状态
export const preparationStatus = [
  {
    id: 0,
    name: '待下发',
    color: '#E02020',
    background: 'rgba(224, 32, 32, 0.1)'
  },
  {
    id: 1,
    name: '待备料',
    color: '#0091FF',
    background: 'rgba(0,145,255,0.1)'
  },
  {
    id: 2,
    name: '备料中',
    color: '#00BC35',
    background: 'rgba(0, 171, 41, 0.1)'
  },
  {
    id: 3,
    name: '已备料',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  }

]
// 未排程计划状态
export const noPlanStatus = [
  {
    id: 0,
    name: '未排程未下发'
  },
  {
    id: 1,
    name: '未排程已下发'
  },
  {
    id: 4,
    name: '未排程已完成'
  }
]
// 已排程计划状态
export const havePlanStatus = [
  {
    id: 2,
    name: '已排程未下发'
  },
  {
    id: 3,
    name: '已排程已下发'
  },
  {
    id: 5,
    name: '已排程已完成'
  }
]
// 生产状态、工单状态
export const productionStatus = [
  {
    id: 0,
    name: '未开始'
  },
  {
    id: 1,
    name: '进行中'
  },
  {
    id: 2,
    name: '已完成'
  }
  // {
  //   id: 3,
  //   name: '强制结束'
  // }
]
// 物料领退料状态
export const materialsRequisitionStatus = [
  {
    id: 0,
    name: '未开始'
  },
  {
    id: 1,
    name: '进行中'
  },
  {
    id: 2,
    name: '已完成'
  },
  {
    id: 3,
    name: '已撤销'
  }
]
// 计划类型
export const productionType = [
  {
    id: 0,
    name: '主生产计划'
  },
  {
    id: 1,
    name: '物料需求计划'
  }
]
// 工单来源
export const taskSource = [
  {
    id: 0,
    name: '新增'
  },
  {
    id: 1,
    name: '生产计划'
  }
]
// 工序属性
export const procedureClass = [
  {
    id: 0,
    name: '普通'
  },
  {
    id: 1,
    name: '返修'
  }
]

// 区域类型  0：工厂，1：车间， 2：产线、3：线边库
export const areaType = [
  {
    id: 0,
    name: '工厂'
  },
  {
    id: 1,
    name: '车间'
  },
  {
    id: 2,
    name: '产线'
  }
]

// 设备类型
export const equipmentType = [
  {
    id: 0,
    name: '生产设备'
  },
  {
    id: 1,
    name: '非生产设备'
  }
]

// 设备状态
export const equipmentStatus = [
  {
    id: 0,
    name: '空闲中'
  },
  // {
  //   id: 1,
  //   name: '生产中'
  // },
  {
    id: 2,
    name: '维保中'
  }
]

// 维保类型，0:开机生产 1:故障维修
export const equipmentRepairType = [
  {
    id: 0,
    name: '开机生产'
  },
  {
    id: 1,
    name: '故障维修'
  }
]

export const equipmentRepairStatus = [
  {
    name: '未处理',
    id: 0
  }, {
    name: '处理中',
    id: 1
  }, {
    name: '已完成',
    id: 2
  }
]
// 采购申请审批状态
export const purchaseApprovalStatus = [
  {
    id: 0,
    name: '待确认'
  },
  {
    id: 1,
    name: '已确认'
  },
  {
    id: 2,
    name: '已驳回'
  }
]
// 出库类型--物料
export const MaterialsIssueType = [
  {
    id: 0,
    name: '对接erp'
  },

  {
    id: 1,
    name: '生产入库'
  },

  {
    id: 2,
    name: '生产投料'
  },

  {
    id: 3,
    name: '撤回出站'
  },

  {
    id: 4,
    name: '包装'
  }
]
// 入库类型--物料
export const MaterialsWarehousingType = [
  {
    id: 0,
    name: '生产领料'
  },

  {
    id: 1,
    name: '生产报工'
  },

  {
    id: 2,
    name: '对接erp'
  },

  {
    id: 3,
    name: '撤回报工'
  },

  {
    id: 4,
    name: '删除返修'
  },
  {
    id: 5,
    name: '拆包'
  }
]

// 经验类别
export const experienceType = [
  {
    id: 0,
    name: '维修经验'
  },

  {
    id: 1,
    name: '保养经验'
  }
]

// 巡检类型

export const inspectionType = [
  {
    id: 0,
    name: '月度巡检'
  },

  {
    id: 1,
    name: '季度巡检'
  }
]

// 时间日期
export const dateList = (type = 'hour') => {
  return type === 'hour' ? [
    {
      id: 0,
      name: '小时'
    },
    {
      id: 1,
      name: '日'
    },
    {
      id: 2,
      name: '周'
    },
    {
      id: 3,
      name: '月'
    }
  ] : [
    {
      id: 0,
      name: '日'
    },
    {
      id: 1,
      name: '周'
    },
    {
      id: 2,
      name: '月'
    },
    {
      id: 3,
      name: '年'
    }
  ]
}

// 是否延期
export const isDelay = [
  {
    id: 0,
    name: '全部'
  },
  {
    id: 1,
    name: '延期'
  }
]
// 是否暂停
export const isSuspend = [
  {
    id: 0,
    name: '否'
  },
  {
    id: 1,
    name: '是'
  }
]
// 故障状态
export const faultStatus = [
  {
    id: 1,
    name: '待审核',
    color: '#E02020',
    background: 'rgba(224, 32, 32, 0.1)'
  },
  {
    id: 2,
    name: '待接单',
    color: '#607FFF',
    background: 'rgba(96, 127, 255, 0.1)'
  },
  {
    id: 3,
    name: '待维修',
    color: '#FA6400',
    background: 'rgba(250, 100, 0, 0.1)'
  },
  {
    id: 4,
    name: '维修中',
    color: '#0091FF',
    background: 'rgba(0, 145, 255, 0.1)'
  },
  {
    id: 5,
    name: '待验证',
    color: '#E02020',
    background: 'rgba(224, 32, 32, 0.1)'
  },
  {
    id: 6,
    name: '已完成',
    color: '#9597AE',
    background: 'rgba(149, 151, 174, 0.1)'
  },
  {
    id: 7,
    name: '已驳回',
    color: '#fff',
    background: 'rgba(149, 151, 174, 1)'
  }
]

// 巡检状态
export const inspectionStatus = [
  {
    id: 0,
    name: '跳过',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 1,
    name: '待巡检',
    color: '#fc9100',
    background: 'rgba(252,145,0,0.1)'
  },
  {
    id: 2,
    name: '巡检中',
    color: '#0091FF',
    background: 'rgba(0,145,255,0.1)'
  },
  {
    id: 3,
    name: '已巡检',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 4,
    name: '作废',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  }
]

// 点检状态
export const pointStatus = [
  {
    id: 0,
    name: '跳过',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 1,
    name: '待点检',
    color: '#fc9100',
    background: 'rgba(252,145,0,0.1)'
  },
  {
    id: 2,
    name: '点检中',
    color: '#0091FF',
    background: 'rgba(0,145,255,0.1)'
  },
  {
    id: 3,
    name: '已点检',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 4,
    name: '作废',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  }
]

// 保养状态
export const maintenanceStatus = [
  {
    id: 0,
    name: '跳过',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 1,
    name: '待保养',
    color: '#fc9100',
    background: 'rgba(252,145,0,0.1)'
  },
  {
    id: 2,
    name: '保养中',
    color: '#0091FF',
    background: 'rgba(0,145,255,0.1)'
  },
  {
    id: 3,
    name: '已保养',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 4,
    name: '作废',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  }
]

// 备件状态
export const spareStatus = [
  {
    id: 0,
    name: '待审核'
  },
  {
    id: 1,
    name: '已通过'
  },
  {
    id: 2,
    name: '已驳回'
  },
  {
    id: -1,
    name: '未提交'
  }
]

// 检验条目类型 0:数值 1:文本 2:单选 3:多选 4:附件
export const inspectionItemType = [
  {
    id: 0,
    name: '数值',
    template: {
      tagName: 'el-input-number',
      rules: [],
      props: {
        controlsPosition: 'right'
      },
      children: []
    }
  },
  {
    id: 1,
    name: '文本',
    template: {
      tagName: 'el-input',
      props: {},
      children: []
    }
  },
  {
    id: 2,
    name: '单选',
    template: {
      tagName: 'el-select',
      props: {},
      children: [
        {
          tagName: 'el-option',
          props: {
            name: 'name',
            id: 'id'
          }
        }
      ]
    }
  },
  {
    id: 3,
    name: '多选',
    template: {
      tagName: 'el-select',
      props: {
        multiple: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            name: 'name',
            id: 'id'
          }
        }
      ]
    }
  },
  {
    id: 4,
    name: '附件',
    template: {
      tagName: 'el-upload',
      props: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() }
      },
      rules: [],
      children: [{
        tagName: 'el-button',
        props: { size: 'small', type: 'primary' },
        children: []
      }]
    }
  }
]
export const inspectionNewItemType = [
  {
    id: 0,
    name: '数值',
    template: {
      tagName: 'el-input-number',
      rules: [],
      props: {
        controlsPosition: 'right'
      },
      children: []
    }
  },
  {
    id: 1,
    name: '文本',
    template: {
      tagName: 'el-input',
      props: {},
      children: []
    }
  },
  {
    id: 2,
    name: '单选',
    template: {
      tagName: 'el-select',
      props: {},
      children: [
        {
          tagName: 'el-option',
          props: {
            name: 'name',
            id: 'id'
          }
        }
      ]
    }
  },
  {
    id: 3,
    name: '多选',
    template: {
      tagName: 'el-select',
      props: {
        multiple: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            name: 'name',
            id: 'id'
          }
        }
      ]
    }
  },
  {
    id: 4,
    name: '仅判断是否合格',
    template: {
      tagName: 'el-select',
      props: {},
      children: [
        {
          tagName: 'el-option',
          props: {
            name: 'name',
            id: 'id'
          }
        }
      ]
    }
  },
  {
    id: 5,
    name: '附件',
    template: {
      tagName: 'el-upload',
      props: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() }
      },
      rules: [],
      children: [{
        tagName: 'el-button',
        props: { size: 'small', type: 'primary' },
        children: []
      }]
    }
  }
]
// 质检状态
export const qualityStatus = [
  {
    id: 1,
    name: '待质检',
    color: '#E02020',
    background: 'rgba(224, 32, 32, 0.1)'
  },
  {
    id: 2,
    name: '质检中',
    color: '#0091FF',
    background: 'rgba(0, 145, 255, 0.1)'
  },
  {
    id: 3,
    name: '已质检',
    color: '#9597AE',
    background: 'rgba(149, 151, 174, 0.1)'
  },
  {
    id: 4,
    name: '已审核',
    color: '#FFF',
    background: '#778ff3'
  },
  {
    id: 5,
    name: '已审批',
    color: '#fff',
    background: '#909399'
  }
]

export const dataType = [
  {
    id: 0,
    name: '整型'
  },
  {
    id: 1,
    name: '浮点型'
  },
  {
    id: 2,
    name: '文本'
  },
  {
    id: 3,
    name: '日期'
  },
  {
    id: 4,
    name: '布尔'
  }
]
// 外协申请状态
export const outSourceStatus = [
  {
    id: 0,
    name: '待审核',
    color: '#E02020',
    background: 'rgba(224, 32, 32, 0.1)'
  },
  {
    id: 1,
    name: '已审核',
    color: '#9597AE',
    background: 'rgba(149, 151, 174, 0.1)'
  },
  {
    id: 2,
    name: '已驳回',
    color: '#fff',
    background: 'rgba(149, 151, 174, 1)'
  }
]

export const statusList = [
  {
    id: 0,
    name: '待处理',
    color: '#FA6400',
    background: 'rgba(250, 100, 0, 0.1)'
  },
  {
    id: 1,
    name: '处理中',
    color: '#00AB29',
    background: 'rgba(0, 171, 41, 0.1)'
  },
  {
    id: 2,
    name: '已处理',
    color: '#9597AE',
    background: 'rgba(149, 151, 174, 0.1)'
  }
]

export const qualityInspectionType = [
  {
    id: 1,
    name: '过程检'
  },
  {
    id: 2,
    name: '来料检'
  },
  {
    id: 3,
    name: '出货检'
  }
]
// 返修管理状态
export const repairStatus = [
  {
    id: 0,
    name: '待处理',
    color: '#FA6400',
    background: 'rgba(250, 100, 0, 0.1)'
  },
  {
    id: 1,
    name: '部分处理',
    color: '#00BC35',
    background: 'rgba(0, 171, 41, 0.1)'
  },
  {
    id: 2,
    name: '已处理',
    color: '#9597AE',
    background: 'rgba(149, 151, 174, 0.1)'
  }
]
// 返报废管理状态
export const restStatus = [
  {
    id: 0,
    name: '待处理',
    color: '#FA6400',
    background: 'rgba(250, 100, 0, 0.1)'
  },
  {
    id: 1,
    name: '已处理',
    color: '#9597AE',
    background: 'rgba(149, 151, 174, 0.1)'
  }
]
// 工序任务状态
export const produceTaskStatus = [
  {
    id: 0,
    name: '待开始',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 1,
    name: '进行中',
    color: '#0091FF',
    background: 'rgba(0,145,255,0.1)'
  },
  {
    id: 2,
    name: '已完成',
    color: '#9597AE',
    background: 'rgba(149,151,174,0.1)'
  },
  {
    id: 3,
    name: '强制结束',
    color: '#ff797a'
    // background: 'rgba(149,151,174,0.1)'
  }
]
// 备料申请提交状态
export const partApplyStatus = [
  {
    id: 0,
    name: '待提交'
  },
  {
    id: 1,
    name: '已提交'
  },
  {
    id: 2,
    name: '已采购'
  }
]
// 备料申请紧急状态e
export const emergencyStatus = [
  {
    id: 0,
    name: '一般'
  },
  {
    id: 1,
    name: '紧急'
  },
  {
    id: 2,
    name: '特急'
  }
]
// 质检任务状态
export const qualityTaskStatus = [
  {
    id: 0,
    name: '未开始',
    color: '#58576f',
    background: 'rgb(87,89,111)'
  },
  {
    id: 1,
    name: '质检中',
    color: '#607FFF',
    background: 'rgb(96, 127, 255)'
  },
  {
    id: 2,
    name: '已结束',
    color: '#9597AE',
    background: 'rgb(149,151,174)'
  }
]
// Bom方向
export const bomDirection = [
  {
    id: 0,
    name: '向上'
  },
  {
    id: 1,
    name: '向下'
  }
]
// 单据类型
export const docType = [
  {
    id: 0,
    name: '生产工单'
  },
  {
    id: 1,
    name: '售后服务单'
  },
  {
    id: 2,
    name: '销售订单'
  }
]
// 单据状态
export const docStatus = [
  {
    id: 0,
    name: '尚未领料'
  },
  {
    id: 1,
    name: '开始领料'
  },
  {
    id: 2,
    name: '结束领料'
  }
]
// 领退料状态
export const receiptStatus = [
  {
    id: 0,
    name: '工单领料'
  },
  {
    id: 1,
    name: '工单退料'
  },
  {
    id: 2,
    name: '工单挪料'
  }
]
// 项目交付类型
export const projectDeliveryType = [
  {
    id: 0,
    name: '一次交付'
  },
  {
    id: 1,
    name: '分批交付'
  }
]
// 项目状态
export const projectStatus = [
  {
    id: 0,
    name: '未生效'
  },
  {
    id: 1,
    name: '未计划'
  },
  {
    id: 2,
    name: '进行中'
  },
  {
    id: 3,
    name: '已交付'
  },
  {
    id: 4,
    name: '已结案'
  }
]
// 审核状态
export const checkStatus = [
  {
    id: 0,
    name: '草稿'
  },
  {
    id: 1,
    name: '审核中'
  },
  {
    id: 2,
    name: '已审核'
  }
]
// 计划节点属性
export const planNodeAttribute = [
  {
    id: 0,
    name: '固定'
  },
  {
    id: 1,
    name: '更新'
  },
  {
    id: 2,
    name: '随动'
  },
  {
    id: 3,
    name: '混合'
  }
]
// 开始完成时间属性
export const startFinishTimeAttribute = [
  {
    id: 0,
    name: '固定'
  },
  {
    id: 1,
    name: '更新'
  },
  {
    id: 2,
    name: '随动'
  }
]
// 异常分类标准
export const abnormalClassifyCriteria = [
  {
    id: 0,
    name: '业务部门'
  },
  {
    id: 1,
    name: '异常类型'
  }
]

// 专业
export const majorTypeOptions = [
  {
    id: 0,
    name: '全部'
  },
  {
    id: 1,
    name: '电气'
  },
  {
    id: 2,
    name: '机械'
  }
]
// 异常紧急程度
export const emergencyDegreeOptions = [
  { name: '紧急', id: 0 },
  { name: '一般', id: 1 },
  { name: '低', id: 2 }
]
export const whether = [
  {
    id: 0,
    name: '业务部门'
  },
  {
    id: 1,
    name: '异常类型'
  }
]
export const judgeResultOptions = [
  { name: '无需处理，直接关闭', id: 1 },
  { name: '需要处理', id: 0 }
]
export const checkResultOptions = [
  { name: '驳回，继续处理', id: 0 },
  { name: '通过已关闭', id: 1 }
]
// 最终结果
export const finalResultOptions = [
  { name: '已解决', id: 0 },
  { name: '长期跟踪', id: 1 },
  { name: '其他', id: 2 }
]
// 变更类型
export const changeType = [
  {
    id: 0,
    name: '设计变更'
  },
  {
    id: 1,
    name: '客户变更'
  },
  {
    id: 2,
    name: '供应商变更'
  }
]
// 装备云变更类型
export const changeNewType = [
  {
    id: 0,
    name: '物料变更'
  },
  {
    id: 1,
    name: '设计变更'
  },
  {
    id: 2,
    name: '工艺变更'
  }
]
// 变更状态
export const changeStatus = [
  {
    id: 0,
    name: '草稿'
  },
  {
    id: 1,
    name: '审核中'
  },
  {
    id: 2,
    name: '已审核'
  },
  {
    id: 3,
    name: '执行中'
  },
  {
    id: 4,
    name: '部分执行'
  },
  {
    id: 5,
    name: '已执行'
  }
]

// 超产报工
export const reportType = [
  {
    id: 1,
    name: '启用'
  },
  {
    id: 0,
    name: '禁用'
  }
]
// 协微辅材 操作类型
export const operationType = [
  {
    id: 0,
    name: '换上'
  },
  {
    id: 1,
    name: '换下'
  },
  {
    id: 2,
    name: '返厂'
  },
  {
    id: 3,
    name: '运入'
  },
  {
    id: 4,
    name: '完成测试'
  },
  {
    id: 5,
    name: '运出'
  }
]
// 协微辅材 状态
export const auxiliaryType = [
  {
    id: 0,
    name: '待上机'
  },
  {
    id: 1,
    name: '正常运行'
  },
  {
    id: 2,
    name: '待返厂'
  },
  {
    id: 3,
    name: '返厂中'
  },
  {
    id: 4,
    name: '待测试'
  },
  {
    id: 5,
    name: '待运出'
  }
]
