<template>
    <div class="materials-class" :style="{height:type === 'mbom'|| type === 'warnMoreM' ? 'auto':'100%',display: type === 'warnMoreM' ? 'flex':'block'}">
      <img v-if="type === 'warnMoreM' && showWarnIcon" src="@/assets/information/bom/warning.png" style="width: 15px;height:15px;margin-top:22px;margin-left:10px">
        <div v-show="isOpen" style="height:100%;width:100%">
      <div class="top" v-if="type != 'warnMoreM'">
        <div>BOM层级</div>
        <el-input-number
              v-model="level"
              controls-position="right"
              :min="0"
              :max="maxLevel"
              class="min-input"
            />
            <el-button type="default" size="small" @click="changeMBOM" v-if="type=='bom'">转MBOM</el-button>
           <div v-else-if="type=='changeBom'">
            <el-button type="primary" size="small" @click="confirmChange">确定</el-button>
            <el-button type="default" size="small" @click="cancelChange">取消</el-button>
          </div>
            <el-switch :value="isCloseProcess" title="是否开启工艺路线" v-else inactive-color="#eee" @change="switchStatus"/>
            <el-button type="primary" size="small" @click="exportBomLevel" v-if="type=='bom'|| type=='mbom'">全部导出</el-button>
        <div v-if="mySelfShowFlag" @click="changeWidthStatus(1)" title="点击收起" style="cursor: pointer;"><i class="el-icon-d-arrow-left"></i></div>
        <div v-if="type=='changeBom' && showWithdraw" style="padding-top:2px;">
            <el-button type="success" size="small" @click="withdrawMaterials">撤回</el-button>
            <el-button type="primary" size="small" @click="withdrawalByProcess">按工序撤回</el-button>
          </div>
        <div v-if="type === 'changeBom'" @click="changeWithdrawStatus(!showWithdraw)" :title="showWithdraw ? '点击收起撤回' : '点击展开撤回'" style="cursor: pointer; padding-top: 2px;">
          <i :class="showWithdraw ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"></i>
        </div>
      </div>
      <div class="tree" style="height: auto">
        <BomTree ref="zTrees" :view="view" :nodes="nodes" @command="command" @nodeClick="nodeClick" @chooseNode="chooseNode" @getTreeLevel="getTreeLevel"
        :level="level" :type="type"  @onFilterDrop="onFilterDrop" :showWithdraw="showWithdraw"/>
      </div>
      <MDialog v-model="visible" :title="currentType" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
      </MDialog>
      <MDialog
      v-model="materialVisible"
      :append-to-body="true"
      title="撤回投入产出物料清单"
      @onOk="submitWithdrawByMaterials"
    >
      <div class="model-tit">
        <div class="tit">目标工序</div>
        <el-input v-model="targetProcess" class="ipt" disabled/>
      </div>
      <div class="model-tit">
        <div>
        <div style="display:flex">
        <div class="tit" style="width:60%">投入物料</div>
        <div class="tit" style="width:15%;text-align:center">使用量</div>
        <div class="tit" style="width:15%;text-align:center">损耗率</div>
        <div class="tit" style="width:10%;text-align:center">工序</div>
        </div>
        <BomTree ref="zTrees" :view="true" :nodes="dropNode" @chooseNode="chooseMaterialsNode" :type="'chooseMaterialsChangeBom'"/>
        </div>
      </div>
    </MDialog>

    <MDialog
      v-model="processVisible"
      :append-to-body="true"
      title="按工序撤回投入产出物料清单"
      @onOk="submitWithdrawByProcess"
    >
      <div class="model-tit">
        <div class="tit">目标工序</div>
        <el-select v-model="targetProcess" style="width:100%" class="ipt" placeholder="请选择" @change="changeProcess" filterable>
              <el-option
                v-for="item in processList"
                :key="item.id"
                :label="item.procedureName"
                :value="item.id"
              />
            </el-select>
      </div>
      <div class="model-tit">
        <div>
        <div style="display:flex">
        <div class="tit" style="width:60%">投入物料</div>
        <div class="tit" style="width:20%;text-align:center">使用量</div>
        <div class="tit" style="width:20%;text-align:center">损耗率</div>
        </div>
        <BomTree ref="zTrees" :view="true" :nodes="dropNode" @chooseNode="chooseProcessNode" :type="'chooseProcessChangeBom'"/>
        </div>
      </div>
    </MDialog>

        </div>
        <div class="top" v-show="!isOpen && mySelfShowFlag"><div @click="changeWidthStatus(0)" title="点击展开" style="cursor: pointer;"><i class="el-icon-d-arrow-right"></i></div></div>
    </div>
  </template>

  <script>
  import BomTree from '@/components/Tree/BomTree'
  import api from '@/api/information/bom'
  import bapi from '@/api/exportAndImport/export'
  import processApi from '@/api/information/production/process'
  import dayjs from 'dayjs'
  import Cookies from 'js-cookie'

  export default {
    name: 'MaterialsClass',
    components: { BomTree },
    props: {
      view: {
        type: Boolean,
        default: false
      },
      mySelfShowFlag:{
        type: Boolean,
        default: true
      },
      materialsBomId:{
        type: Number,
        default:0
      },
      type:{
        type: String,
        default:''
      },
      processFlowVersionId:{
        type: Number,
        default:0
      },
      chooseProcessList:{
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        nodes: [],
        currentClicked: '',
        visible: false,
        currentType: 'add',
        formData: {},
        level:0,
        isOpen:true,
        formList: [
          {
            label: 'BOM类别名称',
            key: 'name',
            tagName: 'el-input',
            required: true
          },
          {
            label: '上级分类',
            key: 'parentId',
            tagName: 'el-select',
            props: {
              clearable: true
            },
            children: [
              {
                tagName: 'el-option',
                props: {
                  label: 'name',
                  value: 'id'
                }
              }
            ]
          }
        ],
        materialsClassList: [{
          id: -1,
          name: ' '
        }],
        isCloseProcess:true,
        chooseNodes:[],
        formatNodes:[],
        maxLevel:1,
        showWarnIcon:false,
        dropNode:[],
        materialVisible:false,
        targetProcess:'',
        processVisible:false,
        processList:[],
        chooseProcessNodes:[],
        processMaterialsNodes:[],
        chooseProcessFlowVersionId:'',
        chooseMaterialsNodes:[],
        showWithdraw:true
      }
    },
    mounted() {
      this.getMaterialsClassList()
      Cookies.remove("z_tree" + window.location)
    },
    methods: {
        changeWidthStatus(type){
            this.isOpen = type ? false : true
            this.$emit('changeWidth',  this.isOpen)
        },
        changeWithdrawStatus(type){
          this.showWithdraw = type
        },
      async getMaterialsClassList() {
        let res
        if(this.type === 'mbom' || this.type === 'warnMoreM'){
          res = await api.getMBomLevel({materialsBomId:this.materialsBomId,showProcessFlow:this.isCloseProcess})
        }else if(this.type === 'changeBom'){
          res = await api.getBomLevel({materialsBomId:this.materialsBomId,showModuleLevel:false})
        }else{
          res = await api.getBomLevel({materialsBomId:this.materialsBomId})
        }
        if (res) {
          this.materialsClassList = [{
            id: -1,
            name: ' '
          }]
          this.materialsClassList = [...this.materialsClassList, ...res]
          let a = this._.cloneDeep(res)
         if(this.type === 'warnMoreM'){
            let arr
            if(a[0].moreMaterialsList.length || a[0].lessMaterialsList.length){
              this.showWarnIcon = true
                if(!a[0].lessMaterialsList.length && a[0].moreMaterialsList.length){
                 arr = [{ name:`多了${a[0].moreMaterialsList.length}件物料`,children:a[0].moreMaterialsList,quantity:''}]
                }else if(a[0].lessMaterialsList.length && !a[0].moreMaterialsList.length){
                  arr  = [{name:`少了${a[0].lessMaterialsList.length}件物料`,children:a[0].lessMaterialsList,quantity:''}]
                }else if(a[0].lessMaterialsList.length && a[0].moreMaterialsList.length){
                  arr = [{ name:`多了${a[0].moreMaterialsList.length}件物料`,children:a[0].moreMaterialsList,quantity:''},{name:`少了${a[0].lessMaterialsList.length}件物料`,children:a[0].lessMaterialsList,quantity:'',}]
                }
                this.nodes = arr.map(item=>{
              return {
               ...item,
               children:item.children.map(items=>{
                return {
                  ...items,
                  name:`${items.code} | ${items.name}`
                }
               })

              }
            })
            }else{
              this.showWarnIcon = false
              arr = []
            }
          }else{
            this.nodes = a.map(item =>{
            return this.assignChecked(item);
          })
          }

          this.insertIconSkin(this.nodes)
          this.$emit('setMaterialsClass', res)
        }
      },

      assignChecked(item) {
        if (item.children) {
          item.children = item.children.map(child => {
            return this.assignChecked(child);
          });
        }
        return {
          ...item,
          name:`${item.materialsCode} | ${item.name}`,
          checked: item.used,
          chkDisabled:item.used,
          drop:!item.used,
          rate:0
        };
      },
      withdrawalByProcess(){
        this.targetProcess = ''
        this.dropNode = []
        this.processList = this.chooseProcessList
        this.processVisible = true
      },
      changeProcess(val){
        let obj =  this.processList.find(item => item.id === val);
        this.chooseProcessFlowVersionId = obj.processFlowVersionId
       this.dropNode = obj.planMaterialsList ? obj.planMaterialsList:[]
       this.dropNode = this.dropNode.map(item=>{
        return {
          ...item,
          name:`${item.code} | ${item.name}`,
          checked:true
        }
       })
       this.chooseProcessNodes = this._.cloneDeep(this.dropNode)
      },
      switchStatus(val){
        this.isCloseProcess = val
        this.getMaterialsClassList()
      },
     insertIconSkin(arr) {
      arr.forEach(obj => {
        obj.iconSkin = null; // 设置父节点的 iconSkin 为 null
        if (obj.children && obj.children.length > 0) {
          if(obj.materialsAttributeName === '自制件') obj.iconSkin = 'diy03'
          this.insertIconSkin(obj.children)
        } else {
          if(obj.type == 1){
            obj.iconSkin = 'diy02'
          }
          else if(obj.type == 2){
            obj.iconSkin = ''
          }else {
            obj.iconSkin = 'diy03'
          }
        }
      })
      },
      getTreeLevel(val){
        this.maxLevel = val -1
      },
      command(name, node) {
        this[name](node)
      },
      // 转MBOM
      changeMBOM(){
        this.$router.push({ name: 'changeToMbom', query: { id: this.materialsBomId }})
      },
      add() {
        this.formData = { parentId: this.currentClicked }
        this.formList[1].children[0].options = this.materialsClassList
        this.currentType = '新增BOM类别'
        this.visible = true
      },
      modify(node) {
        this.formData = this.createResetData(node)
        this.formList[1].children[0].options = this.materialsClassList
          .filter(item => item.parentId !== node.id && node.id !== item.id)
        this.currentType = '修改BOM类别'
        this.visible = true
      },
      del(node) {
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.deleteBomClass({ id: node.id })
          if (res) {
            this.$message.success('删除成功')
            await this.getMaterialsClassList()
          }
        })
      },
      async export(node) {
        let res
        if(this.type === 'mbom'){
          res = await bapi.exportMBomLevelDownMList({ materialsBomLevelId: node.id })
        }else{
          res = await bapi.exportBomLevelDownMList({ materialsBomLevelId: node.id })
        }
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    async exportBomLevel(){
      let res
      if(this.type === 'mbom'){
          res = await api.exportMBomLevelList({ materialsMBomId: this.materialsBomId })
        }else{
          res = await api.exportBomLevelList({ materialsBomId: this.materialsBomId })
        }
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
      nodeClick(treeId, node) {
        if (this.currentClicked === node.id || node.id === -1) this.currentClicked = ''
        else this.currentClicked = node.id
        this.$emit('nodeClick', this.currentClicked,node)
      },
      chooseNode(node,nodes){
        this.chooseNodes = node
        this.formatNodes = nodes
      },
      onFilterDrop(node,nodes){
        if(!this.chooseNodes.length){
          this.$message.info('请选择投入物料！')
         return false
        }
        this.$emit('onFilterDropss', this.chooseNodes,this.formatNodes)
      },
      async confirmChange(){
        if(this.processFlowVersionId){
        const res = await api.changeBomToMBom({
          materialsBomId:this.materialsBomId,
          processFlowVersionId:this.processFlowVersionId
        })
        if(res){
            // this.$router.push({ name: 'bomList'})
            if (res.length > 0) {
               this.$confirm('修改工艺路线后将同步工单，是否确认？', '同步工单', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(async() => {
                  await processApi.synchronizedWorkOrder({productionPlanNumbers:res })
                })
            }
            this.$message.success('操作成功')
            this.$store.dispatch('tagsView/delCachedView', this.$route)
            this.$router.replace({
              path: '/refresh',
              params: this.$route.params,
              query: this.$route.query
            })
          }
        }else{
          this.$message.info('BOM未绑定工艺路线！')
        }
      },
      withdrawMaterials(){
        this.targetProcess = ''
        this.dropNode = []
        const checkboxes = document.querySelectorAll('.process-diy-style.input-class')
        const checkedValues = []
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkedValues.push(parseInt(checkboxes[i].value));
          }
        }
        if(checkedValues.length){
          let arr = this.flattenArray(this.nodes)
          let array = this.delChildren(arr)
          this.dropNode = array.filter(item => checkedValues.includes(item.id))
          this.dropNode = this.dropNode.map(item=>{
          return {
            ...item,
            checked:true,
            chkDisabled:false,
            drop:true
          }
        })
        this.chooseMaterialsNodes = this._.cloneDeep(this.dropNode)
        const isTrue = this.dropNode.every(item => item.procedureCode === this.dropNode[0].procedureCode)
        if(isTrue){
          this.targetProcess = this.dropNode[0].procedureName
        }else{
          this.targetProcess = ''
        }
          this.materialVisible = true
        }else{
          this.$message.info('请选择物料！')
        }

      },
      flattenArray(arr) {
        let flattened = []
        arr.forEach(item => {
          if (!item.children) {
            flattened.push({ ...item });
          } else {
            flattened.push({ ...item });
            flattened = flattened.concat(this.flattenArray(item.children));
          }
        })
        return flattened;
  },
  delChildren(arr){
    arr.forEach(item => {
        if (item.children) {
          delete item.children
        }
      })
      return arr
  },
      cancelChange(){
        this.$router.back()
        // this.$router.push({ name: 'bomDetail', query: { id: this.materialsBomId }})
      },
      submitForm(callback) {
        const { form } = this.$refs.formBuild
        this.$refs.formBuild.formRefs().validate(async(valid) => {
          if (valid) {
            const type = this.currentType === '修改BOM类别' ? 'modify' : 'add'
            const res = await api[`${type}BomClass`](
              this.currentType === '修改BOM类别' ? this.createResetData(form) : this.createSetData(form)
            )
            if (res) {
              this.$message.success(type === 'add' ? '新增成功' : '修改成功')
              this.visible = false
              await this.getMaterialsClassList()
            }
            callback(true)
          } else {
            callback()
          }
        })
      },
      chooseProcessNode(node){
       this.chooseProcessNodes = this._.cloneDeep(node)
       },
       chooseMaterialsNode(node){
       this.chooseMaterialsNodes = this._.cloneDeep(node)
       },
      // 按工序撤回
      async submitWithdrawByProcess(callback){
        if(this.chooseProcessNodes.length>0){
          let obj = {
          procedureDetailsId:this.targetProcess,
          items:this.chooseProcessNodes.map(item=>{
            return {
              procedureDetailsId:this.targetProcess,
              processFlowVersionId:this.chooseProcessFlowVersionId,
              materialId:item.id
            }
          })
        }
       const res = await api.withdrawMaterials(obj)
       if(res){
        this.$message.success('撤回成功')
        this.processVisible = false
        await this.getMaterialsClassList()
        callback(true)
       }else{
        callback()
       }
        }else{
          this.$message.info('请选择物料！')
          callback()
        }
      },
      // 按物料撤回
      async submitWithdrawByMaterials(callback){
        let chooseArr = this.flattenArray(this.chooseMaterialsNodes)
        let obj = {
          items:chooseArr.map(item=>{
            return {
              parentMaterialsId:item.parentMaterialsId,
              procedureDetailsId:item.procedureDetailsId,
              processFlowVersionId:this.processFlowVersionId,
              materialId:item.materialsId
            }
          })
        }
       const res = await api.withdrawMaterials(obj)
       if(res){
        this.$message.success('撤回成功')
        this.materialVisible = false
        await this.getMaterialsClassList()
        callback(true)
       }else{
        callback()
       }
      },
      createSetData(raw) {
        return {
          name: raw.name,
          parentId: raw.parentId || -1
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .materials-class {
    width: 100%;
    height: auto;
    background: #F8F8F8;
    overflow: auto;
    .top {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: #DDE3EB;
      }
      .add-materials-class {
        i {
          color: rgb(96, 127, 255);
          cursor: pointer;
          font-size: 20px;
          position: relative;
          top: 1px;
        }
      }
      .min-input {
        width: 100px;
    }
    }
    .tree {
      height: calc(100% - 41px);
      padding: 10px 0 0 8px;
      overflow: auto;
    }

  }
  .model-tit {
    .min-input{
        width:100px;
    }
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 700;
    color: #4a4f76;
    padding:10px 0;
  }

}
  </style>
