import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicConfig/private/basicConfig/'
const baseUrl2 = 'api/web/v1/basicConfig/public/basicConfig/'
export default {
  // 获取
  getList(data) {
    return request.get(baseUrl + 'getAllBasicConfigByTree', data)
  },
  getList2(data) {
    return request.get(baseUrl + 'getBasicConfigList', data)
  },
  // 修改
  modifySetting(data) {
    return request.put(baseUrl + 'updateBatch', data)
  },
  modifySingleSetting(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  getDetail(data) {
    return request.get(baseUrl + 'getBasicConfigList', data)
  },
  getCode(data) {
    return request.get(baseUrl + 'getBasicConfigDetailByCode', data)
  },
  // 版本号和链接
  getVersion(code) {
    return request.get(baseUrl2 + `getBasicConfigDetailByCode?code=${code}`
    )
  },
  getAllSetting(data) {
    return request.get(baseUrl2 + `getAllBasicConfigByTree`, data)
  },
  getSystemConfig(data) {
    return request.get(`api/web/v1/systemConfig/private/systemConfig/code`, data)
  }
}
