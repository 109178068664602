import request from '@/utils/requestForAsync'
import { Encrypt } from '@/utils/sercet'
import { getUrl, getUUid } from '@/utils'
const baseUrl = 'api/web/v1/basicData/materials/private/'

export default {
  // 获取物料-不分页
  getMaterialsList(data) {
    return request.get(baseUrl + 'materials/getMaterialsList', data)
  },
  // 获取物料-分页
  getMaterialsListPage(data) {
    return request.get(baseUrl + 'materials/getMaterialsListPage', data)
  },
  // 新增物料
  addMaterials(data) {
    return request.post(baseUrl + 'materials/addMaterials', data)
  },
  // 新增物料
  copyMaterials(data) {
    return request.post(baseUrl + 'materials/addMaterials', data)
  },
  // 修改物料
  modifyMaterials(data) {
    return request.put(baseUrl + 'materials/updateOne', data)
  },
  // 删除物料
  delMaterials(data) {
    return request.del(baseUrl + 'materials/deleteOne', data)
  },
  // 批量删除物料
  delBatchMaterials(data) {
    return request.post(baseUrl + `materialsOld/deleteList`, data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${path}/${status}?id=${id}`)
  },
  // 获取物料辅助单位根据id
  getMaterialsUnitMappingList(id) {
    return request.get(baseUrl + `materialsUnitMapping/getMaterialsUnitMappingList?searchVal=${Encrypt(JSON.stringify(
      [{
        id: getUUid(),
        fieldName: 'materialsId',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      }]
    ))}`)
  },
  // 获取物料包装类根据id
  getMaterialsContainerMappingList(id) {
    return request.get(baseUrl + `materialsContainerMapping/getMaterialsContainerMappingList?searchVal=${Encrypt(JSON.stringify(
      [{
        id: getUUid(),
        fieldName: 'materialsId',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      }]
    ))}`)
  },
  // 获取物料详情
  getMaterialsDetailList(id) {
    return request.get(baseUrl + `materials/getMaterialsDetail?id=${id}`)
  },
  // 获取物料分类-不分页
  getMaterialsClassList(data) {
    return request.get(baseUrl + 'materialsClass/getMaterialsClassList', data)
  },
  // 获取物料分类-分页
  getMaterialsClassListPage(data) {
    return request.get(baseUrl + 'materialsClass/getMaterialsClassListPage', data)
  },
  // 新增物料分类
  addMaterialsClass(data) {
    return request.post(baseUrl + 'materialsClass/addMaterialsClass', data)
  },
  copyMaterialsClass(data) {
    return request.post(baseUrl + 'materialsClass/addMaterialsClass', data)
  },
  // 修改物料分类
  modifyMaterialsClass(data) {
    return request.put(baseUrl + 'materialsClass/updateOne', data)
  },
  // 删除物料分类
  delMaterialsClass(data) {
    return request.del(baseUrl + 'materialsClass/deleteOne', data)
  },
  // 获取物料质检配置
  getMaterialsInspectionSetting(data) {
    return request.get(baseUrl + 'materials/getMaterialsInspectionInfo', data)
  },
  // 保存物料质检配置
  saveMaterialsInspectionSetting(data) {
    return request.post(baseUrl + 'materials/saveMaterialInspectionInfo', data)
  },
  saveMaterialsInspectionSettingIOQC(type, data) {
    return request.post(baseUrl + `materials/saveMaterialInspectionInfoForIoqc?inspectionType=${type}`, data)
  },
  // 恢复默认质检配置
  reMaterialsInspectionSetting(data) {
    return request.put(baseUrl + 'materials/returnToDefaultInspectionInfo' + getUrl(data))
  },
  getMaterialsInspectionInfoForIOQC(id) {
    return request.get(baseUrl + `materials/getMaterialsInspectionInfoForIoqc?materialsId=${id}`)
  },
  createCode(params) {
    return request.get(baseUrl + 'materials/getMaxMaterialsCode', params)
  },
  // 物料规格
  getSpecifications(params) {
    return request.get(baseUrl + 'materials/getSpecifications', params)
  }
}
