<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" :title="title" @onOk="submitForm" @fullScreen="fullScreenChange">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <MaterialsClass :view="true" @setMaterialsClass="setMaterialsClass" @nodeClick="setMaterialsClassId" />
      </template>
      <template slot="right">
        <div class="top">
          <SearchForm :form-list-extend="searchFormList" :form-data.sync="searchFormData" @search="searchTable" />
        </div>
        <div class="materials-info">
          <FunctionHeader
            v-model="functionHeader.searchVal"
            search-title="物料名称/编码/规格"
            class="materials-head"
            :tags="functionHeader.tags"
            :columns-setting="false"
            @search="$refs.mTable.setTableData(true)"
          >
            <div v-if="!single" slot="all">
              <span style="margin: 0 20px;">已选中 <span style="color: #000">{{ getSelectionDataLength }}</span> 项</span>
              <el-button type="text" @click="resetData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable
            ref="mTable"
            :highlight-current-row="single"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            :columns-setting="false"
            @selection-change="selectionChange"
            @current-change="selectionChange"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
          </MTable>
        </div>
      </template>
    </Split>
  </MDialog>
</template>

<script>
import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
import Split from '@/components/Split/Split'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader'
import { columns, ivColumns, sColumns } from './columns'
import { status } from '@/config/options.config'
import { getUUid } from '@/utils'
import api from '@/api/information/materials'
import inventoryApi from '@/api/warehouse/inventory'
import { Encrypt } from '@/utils/sercet'
import { searchFormListAll } from '@/views/warehouse/inventory/form-list'
import maApi from '@/api/information/materials-attribute'
import warehouseApi from '@/api/information/warehouse/model'

export default {
  name: 'SelectMaterials',
  components: { MaterialsClass, Split, SearchForm, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    isProducible: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array,
      default: () => ([])
    },
    selectMaterials: {
      type: Array,
      default: () => ([])
    },
    from: {
      type: String,
      default: 'materials' // inventory
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择物料'
    },
    containerClassId: {
      type: Number,
      default: undefined
    },
    producibleStatus: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      modalShow: false,
      split: {
        offset: 20
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      materialsSearchFormList: [
        {
          key: 'isEnabled',
          tagName: 'el-select',
          colSpan: 6,
          props: {
            placeholder: '请选择物料状态'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: status
            }
          ]
        },
        {
          colSpan: 6,
          key: 'materialsTypeId',
          tagName: 'el-select',
          defaultValue: 1,
          props: {
            placeholder: '请选择物料类型'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      searchFormList: [],
      searchFormData: {},
      searchFormatData: [],
      formOptions: {
        materialsClassList: []
      },
      selectionData: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      fullScreen: false
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      this.columns = this.single ? sColumns : columns
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    },
    from() {
      this.init()
    }
  },
  mounted() {
    this.modalShow = this.visible
    if (this.isAdd) {
      this.functionHeader.tags = [
        {
          tagName: 'el-button',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF' },
          innerText: '新增',
          on: {
            click: this.add
          }
        }
      ]
    } else {
      this.functionHeader.tags = []
    }
    if (this.single) this.columns = sColumns
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    resetData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.materialsClassId = ''
        this.selectionData = this._.cloneDeep(this.selectMaterials)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 新增
    add() {
      this.$emit('addMaterial')
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormatData = []
      if (this.from === 'materials') {
        this.searchFormList = this.materialsSearchFormList
        this.getMaterialsTypeList()
        this.searchFormData = {}
        this.columns = columns
      } else {
        this.searchFormList = searchFormListAll
        this.getWarehouseList()
        this.columns = ivColumns
        this.searchFormData = { isShowZero: 1 }
      }
    },
    // 获取物料类型
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList(this.defaultSearch)
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    selectionChange(data) {
      if (this.single) {
        this.selectionData = []
        if(Array.isArray(data)){
          this.selectionData.push(data[data.length - 1])
        }else {
          this.selectionData.push(data)
        }
        return false
      }
      if (Array.isArray(data)) {
        this.selectionData = data
      } else if (!data.selectable) {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setMaterialsClassId(id) {
      this.materialsClassId = id
      // this.selectionData = []
      this.$refs.mTable.setTableData(true)
    },
    // 获取物料类型
    setMaterialsClass(res) {
      this.formOptions.materialsClassList = res
    },
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData()
    },
    // 获取物料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      let res
      if (this.from === 'materials') {
        if (this.materialsClassId) {
          search.push({
            id: getUUid(),
            fieldName: 'materialsClassId',
            fieldType: 'number',
            fieldValue: this.materialsClassId,
            operator: 'eq'
          })
        }
        if (this.isProducible) {
          search.push({
            id: getUUid(),
            fieldName: 'isProducible',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          })
        }
        res = await api.getMaterialsListPage({
          page: page.currentPage,
          limit: page.pageSize,
          sort: order,
          containerClassId: this.containerClassId || '',
          searchKeyword: this.functionHeader.searchVal,
          producibleStatus: this.producibleStatus,
          searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData, ...this.conditions,
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq',
            },
            {
              id: getUUid(),
              fieldName: 'isVirtualMaterials',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq',
            }]
          ))
        })
      }
      if (this.from === 'inventory') {
        if (this.searchFormData.warehouseIds) {
          this.searchFormData.warehouseIds = this.searchFormData.warehouseIds.join('^')
        }
        res = await inventoryApi.getInventoryListPage({
          page: page.currentPage,
          limit: page.pageSize,
          sort: order,
          searchKeyword: this.functionHeader.searchVal,
          isShowZero: 1,
          materialsClassId: this.materialsClassId,
          searchVal: Encrypt(JSON.stringify([...search, ...this.conditions])),
          ...this.searchFormData
        })
      }

      if (this.selectMaterials) {
        this.selectMaterials.forEach((item) => {
          res.records.forEach(t => {
            if (item.selectable && t.id === item.id) {
              t.selectable = true
            }
          })
        })
      }
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      const a = this.selectionData.filter(item => !item.selectable)
      if (a.length === 0) {
        this.$message.info('请至少选择一条物料')
        callback()
        return
      }
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head{
  padding:0 0 10px 0;
}
</style>
