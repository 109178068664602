import request from '@/utils/request'
const baseUrl = 'api/web/v1/inspection/private/defectProductJudgment/'
export default {
  // 获取不良品判定列表
  getDefectProductJudgmentListPage(data) {
    return request.get(baseUrl + 'getDefectProductJudgmentListPage', data)
  },
  // 获取不良品判定子项列表
 getDefectProductJudgmentItemListPage(data) {
    return request.get(baseUrl + 'getDefectProductJudgmentItemListPage', data)
  },
  //   获取不良品判定详情
  getDefectProductJudgmentDetail(data) {
    return request.get(baseUrl + 'getDefectProductJudgmentDetail', data)
  }
}