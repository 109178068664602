<template>
    <div class="tree-wrap">
      <GiantTree ref="giantTree" class="bomTree" :nodes="nodes" :setting="treeSetting" @onCreated="handleCreated" />
    </div>

  </template>
  
  <script>
  import GiantTree from 'vue-giant-tree'
  import Cookies from 'js-cookie'
  import $ from 'jquery'
  
  export default {
    name: 'Tree',
    components: { GiantTree },
    props: {
      nodes: {
        type: Array,
        required: true,
        default: () => ([])
      },
      view: {
        type: Boolean,
        default: false
      },
      level:{
        type: Number,
        default: 0
      },
      type:{
        type:String,
        default:'bom'
      },
      showWithdraw: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        treeSetting: {
          edit: {
          enable: true,
          showRemoveBtn: false,
          showRenameBtn: false,
          drag: {
            isCopy: false,//拖拽时, 设置是否允许复制节点
            isMove: true,//拖拽时, 设置是否允许移动节点
            prev: false, //拖拽到目标节点时，设置是否允许移动到目标节点前面的操作
            next: false,
            inner: false
          }
        },
          check: {
            enable: false,
            chkStyle: 'checkbox',
            position: 'after',
            chkDisabledInherit: false,
          },
          data: {
            simpleData: {
              enable: true,
              pIdKey: 'parentId'
            }
          },
          view: {
            showLine :true,
            addDiyDom: this.addDiyDom,
            showIcon: true,
            addHoverDom: this.addHoverDom,
            removeHoverDom: this.removeHoverDom
          },
          callback: {
            beforeClick: this.beforeClick,
            onCheck: this.onCheck,
            onDrop: this.onDrop,
            onExpand:this.onExpand,
            onCollapse: this.onCollapse
          },
          ztreeIfo:''
        },
        showCheckbox:true
      }
    },
    watch: {
      level:{
        handler(newVal, oldVal) {
        if (newVal > oldVal) {
         this.getNewLevel(newVal)
            }else {
          this.closeNodesByLevel(oldVal)
        }
    },
    deep:true
  },
  showWithdraw(val) {
      this.showCheckbox = val
      this.$parent.getMaterialsClassList()
    },
},
    mounted() {
      if(this.type === 'changeBom' || this.type === 'chooseChangeBom' || this.type === 'chooseProcessChangeBom' ||  this.type === 'chooseMaterialsChangeBom'){
      this.treeSetting.check.enable = true
      }else{
        this.treeSetting.check.enable = false
      }
        this.treeSetting.edit.enable = this.type === 'changeBom' ? true:false
    },
    methods: {
      onDrop(event, treeId, treeNodes, targetNode, moveType) {
            this.$emit('onFilterDrop')
    },
      async handleCreated(ztreeObj) {
        this.ztreeIfo = this._.cloneDeep(ztreeObj)
        if(this.type != 'warnMoreM'){
          ztreeObj.expandNode(ztreeObj.getNodes()[0], true)
        }
        if(this.type === 'changeBom'){
          var cookie = Cookies.get("z_tree" + window.location);
          var z_tree = new Array();
          if (cookie) {
            z_tree = JSON.parse(cookie)
            for (var i = 0; i < z_tree.length; i++) {
              var node =  ztreeObj.getNodeByParam('id',z_tree[i])
              ztreeObj.expandNode(node,true,false)
            }
          }
        await this.getDisableClass(this.ztreeIfo)
      }
        this.getMaxLevel()
      },
      onExpand(event, treeId, treeNode){
        if(this.type === 'changeBom'){
         this.getDisableClass(this.ztreeIfo)
        var cookie = Cookies.get("z_tree" + window.location);
        var z_tree = new Array()
        if (cookie) {
            z_tree = JSON.parse(cookie)
          }
          if ($.inArray(treeNode.id, z_tree) < 0) {
              z_tree.push(treeNode.id)
          }

          Cookies.set("z_tree" + window.location, JSON.stringify(z_tree))
        }
      },
      onCollapse(event, treeId, treeNode){
        var cookie = Cookies.get("z_tree" + window.location);
        var z_tree = new Array();
        if (cookie) {
              z_tree = JSON.parse(cookie);
        }
        var index = $.inArray(treeNode.id, z_tree);
            z_tree.splice(index, 1);
        for (var i = 0; i < treeNode.children.length; i++) {
            index = $.inArray(treeNode.children[i].id, z_tree);
            if (index > -1) z_tree.splice(index, 1);
        }
        Cookies.set("z_tree" + window.location, JSON.stringify(z_tree))
      },
      async getDisableClass(ztreeObj){
        var disabledNodes = ztreeObj.getNodesByParam("chkDisabled", true)
        for (var i=0; i < disabledNodes.length; i++) {    
         $("#" + disabledNodes[i].tId + "_a").addClass("check-disabled");
       }
      },
      addDiyDom(treeId, treeNode) {
        var name = '';
        if(this.type === 'bom' || this.type === 'mbom' || this.type === 'warnMoreM'){
          name += "<span class='diy-style'>"+treeNode.quantity +"</span>";
        }else if(this.type === 'changeBom'){
          name += "<span class='mbom-diy-style'>"+treeNode.availableQuantity+'/'+treeNode.quantity +"</span>";
            name += "<span class='process-diy-style font-style' id='text5_" + treeNode.id + "'>"+  (treeNode.procedureCode? treeNode.procedureCode : '') +"</span>";
  
            if (treeNode.procedureCode && this.showCheckbox && !treeNode.children) {
            name += '<input class="process-diy-style input-class" style="width:14px;margin:0 16px 0 8px;" type="checkbox" id="text4_' + treeNode.id + '" value="' +treeNode.id + '"  >';
            }

          if(this.type === 'changeBom') $("#" + treeNode.tId + "_a").addClass("change-bom");
        }else if(this.type === 'chooseChangeBom'){
          $("#" + treeNode.tId + "_a").addClass("dialog-name");
          name += '<div style="display:inline-flex;justify-content: space-between;width: 40%;position: absolute;right: 0%;">'
            name += '<div style="width:27%;text-align:center">';
            name += '<input class="input-class" style="width:82%;" type="text" disabled="true" id="text6_' + treeNode.id + '" value="' + treeNode.standardQuantity + '">';
            name += '</div>';
            name += '<div style="width:27%;text-align:center">';
            name += '<input class="input-class" style="width:82%" type="number" id="text1_' + treeNode.id + '" value="' + treeNode.availableQuantity + '"  min="0" step="1">';
            name += '</div>';
            name += '<div style="width:27%;text-align:center">';
            name += '<input class="input-class" style="width:82%" type="number" id="text2_' + treeNode.id + '" value="' + treeNode.rate + '"  min="0" step="0.00001">%';
            name += '</div>';
            name += '<div style="width:20%;text-align:center">';
            name += '<input class="input-class" style="width:20px" type="checkbox" id="text3_' + treeNode.id + '" value="' + treeNode.isKeyMaterials + '">';
            name += '</div>'
            name += '</div>'
        }else if(this.type === 'chooseProcessChangeBom'){
          $("#" + treeNode.tId + "_a").addClass("dialog-name");
          name += '<div style="display:inline-flex;justify-content: space-between;width: 40%;position: absolute;right: 0%;">'
            name += '<div style="width:50%;text-align:center">'+ treeNode.planQuantity + '</div>';
            name += '<div style="width:50%;text-align:center">'+ treeNode.attritionRate + '%' + '</div>';
            name += '</div>'
        }else{
          $("#" + treeNode.tId + "_a").addClass("dialog-name");
          name += '<div style="display:inline-flex;justify-content: space-between;width: 40%;position: absolute;right: 0%;">'
            name += '<div style="width:35%;text-align:center">'+ (treeNode.quantity - treeNode.availableQuantity) + '</div>';
            name += '<div style="width:35%;text-align:center">'+ treeNode.rate +'%'+'</div>';
            name += '<div style="width:25%;text-align:center">'+ treeNode.procedureCode + '</div>';
            name += '</div>'
        }
        $("#" + treeNode.tId + "_a").after(name)
        if (treeNode.children && treeNode.procedureCode) {
         $("#text5_" + treeNode.id).addClass("has-children")
        //  const checkboxes = document.querySelectorAll('#text4_' + treeNode.id)
        //  checkboxes.forEach(item => {
        //     item.addEventListener('change',(e) => { 
        //       this.handleChange(e,treeNode)
        //     })
        //   })
       }
      },
      handleChange(e,el){
      
      },
      getMaxLevel(){
        var maxLevel = 0
        this.ztreeIfo.getNodesByFilter(function(node) {
          var level = node.level
          if (level > maxLevel) {
            maxLevel = level
          }
      return false
    })
        this.$emit('getTreeLevel', maxLevel)
      },
      getNewLevel(newVal){
        let nodes = this.ztreeIfo.getNodesByParam("level",newVal) // 获取所有节点
        for (var i = 0; i < nodes.length; i++) {
          this.ztreeIfo.expandNode(nodes[i], true, false, false) // 展开层级
          if(this.type === 'changeBom'){
         this.getDisableClass(this.ztreeIfo)
        }
        }
      },
      closeNodesByLevel(oldVal){
        let nodes= this.ztreeIfo.getNodesByParam("level",oldVal)
        for (var i = 0; i < nodes.length; i++) {
          this.ztreeIfo.expandNode(nodes[i], false, false, false)
       }
      },
      beforeClick(treeId, treeNode) {
        if (treeNode.id === -1) return false
        const item = document.getElementById(`${treeNode.tId}_a`)
        const classList = item.getAttribute('class').split(' ')
        const index = classList.indexOf('curSelectedNode')
        if (index !== -1) {
          this.$refs.giantTree.ztreeObj.cancelSelectedNode()
          this.$emit('nodeClick', treeId, treeNode)
          return false
        } else {
          this.$emit('nodeClick', treeId, treeNode)
        }
        return true
      },
      onCheck(event, treeId, treeNode){
        // 表示获取 被勾选 的节点集合
       let nodes = this.ztreeIfo.getCheckedNodes(true)
       let filteredNodes = nodes.filter(node => node.getCheckStatus().half === false)
       const filteredObjects = filteredNodes.filter(obj => {
        return !filteredNodes.some(o => o.id === obj.parentId);
      })
       this.$emit('chooseNode', filteredObjects,filteredNodes)
      },
      addHoverDom(treeId, treeNode) {
        const item = document.getElementById(`${treeNode.tId}_a`)
        if (item && !item.querySelector('.tree_extra_btn')) {
          item.style.background = '#F0F2F5'
          if (treeNode.id === -1) return
          const btn = document.createElement('div')
          const btnWrap = document.createElement('div')
          btnWrap.id = `${treeId}_${treeNode.id}_wrap`
          btnWrap.classList.add('tree_extra_wrap_btn')
          btnWrap.style.display = 'inline-block'
          btn.style.display = 'inline-block'
          btn.id = `${treeId}_${treeNode.id}_btn`
          btn.classList.add('tree_extra_btn')
          btn.classList.add('el-icon-more')
          btn.innerText = ''
          if (this.view|| !treeNode.isParent) return
          btnWrap.addEventListener('mouseenter', (e) => {
            e.stopPropagation()
            this.showDropdown(treeId, treeNode)
          })
          btnWrap.addEventListener('mouseleave', (e) => {
            e.stopPropagation()
            this.showDropdown(treeId, treeNode)
          })
          btnWrap.appendChild(btn)
          item.appendChild(btnWrap)
        }
      },
      removeHoverDom(treeId, treeNode) {
        const item = document.getElementById(`${treeNode.tId}_a`)
        if (item) {
          item.style.background = ''
          if (treeNode.id === -1) return
          if (this.view) return
          const btn = item.querySelector('.tree_extra_wrap_btn')
          if (btn) {
            item.removeChild(btn)
          }
        }
      },
      showDropdown(treeId, treeNode) {
        const item = document.getElementById(`${treeNode.tId}_a`)
        const btnWrap = item.querySelector('.tree_extra_wrap_btn')
        const dropDown = item.querySelector('.c-drop-down')
        if (dropDown) {
          btnWrap.removeChild(dropDown)
        } else {
          const div = document.createElement('div')
          div.classList.add('c-drop-down')
          div.innerHTML = '<ul>' +
            // '<li name="modify">编辑</li>' +
            '<li name="export">导出</li>' +
            '</ul>'
          Array.from(div.querySelectorAll('li')).forEach(li => {
            li.addEventListener('click', (e) => {
              e.stopPropagation()
              const name = li.getAttribute('name')
              this.$emit('command', name, treeNode)
            })
          })
          btnWrap.appendChild(div)
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
   .ztree li{
    position: relative;
   }
  .ztree li span.button.diy02_ico_open
  {
    background: url("../../assets/narrow-amplification/arro.png");
  }
  .ztree li span.button.diy02_ico_docu
  {
    background: url("../../assets/information/bom/process.png") no-repeat;
    background-size:16px 16px;
  }
  .ztree li span.button.diy03_ico_docu
  {
    background: url("../../assets/information/bom/material.png") no-repeat;
    background-size:16px 16px;
  }
  .ztree li span.button.diy03_ico_open, .ztree li span.button.diy03_ico_close
  {
    background: url("../../assets/information/bom/material.png") no-repeat;
    background-size:16px 16px;
  }
.button.checkbox_true_disable{
  border-color: #d7dde4 !important;
    background-color: #f3f3f3 !important;
}
.button.checkbox_true_disable:after{
  border-right: 2px solid #d7dde4 !important;
    border-bottom: 2px solid #d7dde4 !important;
}
  .button.chk.radio_true_full:after,
  .button.chk.radio_true_part:after {
    top: 2px !important;
    left: 2px !important;
  }
.tree-wrap {
  .bomTree {
    .diy-style {
      position:absolute;
      right:10px;
      top:0;
      line-height:42px;
    }
    .mbom-diy-style {
      position:absolute;
      right:60px;
      top:0;
      line-height:42px;
    }
    .process-diy-style{
      position:relative;
      left:8px;
      top:0;
      line-height:42px;
    }
    .has-children{
      color:#3399FF
    }
    .input-class{
      width: 100px;
      border: 1px solid #C0C4CC;
      border-radius: 4px;
      height: 32px;
      text-align: center;
      vertical-align: middle;
  }
        .button {
          width: 18px;
        }
        a[treenode_a] {
          position: relative;
          height: 42px;
          line-height: 42px;
          width: calc(100% - 30px);
          display: inline-flex;
          align-items: center;
        }
        .change-bom{
          width: calc(100% - 98px) !important;
        }
        .dialog-name{
          width:58% !important;
        }
        .check-disabled{
          color: #9A9A9A !important;
          pointer-events: none;
        }
        .curSelectedNode {
          .node_name {
            background: none !important;
            padding: 0px;
          }
        }
        .node_name{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 30px);
        }
      //   .node_name::before{
      //     position: absolute;
      // top: 5px;
      // left: 5px;
      // content: "";
      // transition: -webkit-transform .3s ease;
      // -webkit-transition: -webkit-transform .3s ease;
      // transition: transform .3s ease;
      // transition: transform .3s ease,-webkit-transform .3s ease;
      // -webkit-transform: rotate(0deg);
      // transform: rotate(0deg);
      // -webkit-transform-origin: 25% 50%;
      // transform-origin: 25% 50%;
      // border: 6px solid;
      // border-color: transparent transparent transparent #666;
      //   }
        
      .tree_extra_wrap_btn {
        position: absolute;
        left: 0;
        z-index: 999;
        text-align: left;
        top:0;
        .el-icon-more {
          transform: rotate(90deg);
          width: 20px;
          height: 16px;
          background: #E9EAEC;
          border-radius: 2px;
          line-height: 16px;
          text-align: center;
        }
        .c-drop-down {
          border-radius: 4px;
          box-shadow: 0 0 2px 2px rgba(0 , 0, 0, .1);
          background: #ffffff;
          ul {
            padding: 0 !important;
            position: relative;
            li {
              padding: 8px 20px;
              line-height: 16px;
              &:hover {
                background: rgb(240, 242, 245);
              }
            }
            &:before {
              content: '';
              display: inline-block;
              width: 0;
              height: 0;
              border-width: 0 5px 5px;
              border-style: solid;
              border-color: transparent transparent #fff;
              position: absolute;
              left: 5px;
              top: -5px;
              box-shadow: 2px 2px 2px -2px rgba(0 , 0, 0, .1);
            }
          }
        }
      }
      }
  }
  </style>
  