<template>
  <div class="flow-wrapper">
    <!-- <el-button size="small" type="primary" style="margin-top: 15px" @click="onExpand">增加检验项目</el-button>-->
    <div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        search-title="请输入定位码/检验项目"
        :import-name="
          handleType === 'view' || handleType === 'add' ? '' : functionHeader.importName
        "
        :processId="processFlowId"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :columns-setting="false"
        @search="searchTable(functionHeader.searchVal)"
      >
      </FunctionHeader>
      <div class="m-table">
        <el-table :data="BOMList" :show-header="true">
          <el-table-column prop="index" label="序号" width="80">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="serialsCode" label="工序定位码"></el-table-column>
          <el-table-column prop="procedureName" label="工序名称"></el-table-column>
          <el-table-column prop="functionCode" label="功能码"></el-table-column>

                <el-table-column prop="inspectionEntryName" label="检验项目"></el-table-column>
                <el-table-column
                  prop="inspectionStandard"
                  label="检验标准"
                  width="200"
                ></el-table-column>
                <el-table-column prop="inspectionMethod" label="检测方法"></el-table-column>

                <el-table-column prop="parameterType" label="检测值类型">
                  <template slot-scope="scope">
                    {{ scope.row.parameterType | parameterTypeName }}
                  </template>
                </el-table-column>
                <el-table-column prop="defaultValue" label="默认值"></el-table-column>
                <el-table-column prop="oossiblevalue" label="可能值"></el-table-column>
                <el-table-column prop="standardalue:" label="标准值"></el-table-column>

                <el-table-column prop="upperLimit" label="上偏差"></el-table-column>
                <el-table-column prop="lowerLimit" label="下偏差"></el-table-column>

        </el-table>
      </div>
    </div>
  </div>
</template>
  <script>
import listImg from "@/assets/information/process/切换视图-列表视图@2x.png";
import listActiveImg from "@/assets/information/process/切换视图-列表视图备份@2x.png";
import flowActiveImg from "@/assets/information/process/结构@2x.png";
import flowImg from "@/assets/information/process/结构备份@2x.png";
import { insepctionProjectColumns } from "./columns";
import FunctionHeader from "@/components/FunctionHeader";
import { inspectionNewItemType } from "@/config/options.config";
export default {
  name: "InspectionProject",
  components: { FunctionHeader },
  filters: {
    parameterTypeName(val) {
      return inspectionNewItemType.find((item) => item.id === val).name;
    },
  },
  props: {
    processFlowVersion: {
      type: Object,
      default: () => ({}),
    },
    processFlowId: {
      type: Number,
      default: undefined,
    },
    handleType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listImg,
      listActiveImg,
      flowActiveImg,
      flowImg,
      radio: "list",
      insepctionProjectColumns,
      BOMList: [],
      BOMList2:[],
      isExpand: true,
      functionHeader: {
        searchVal: "",
        tags: [
          //   {
          //     tagName: 'el-button',
          //     props: { type: 'primary', size: 'small' },
          //     style: { width: 'auto', background: '#607FFF' },
          //     innerText: '新增',
          //     on: {
          //       click: this.add
          //     }
          //   }
        ],
        importName: "quality",
      },
      visible: false,
    };
  },
  computed: {
    getColumns() {
      if (!this.handleType) {
        return this.insepctionProjectColumns;
      } else {
        return this.insepctionProjectColumns.filter((item) => {
          return item.label !== "操作";
        });
      }
    },
  },
  watch: {
    "processFlowVersion.id": {
      immediate: false,
      handler(val) {
        val && this.resetData && this.init();
      },
    },
  },
  created() {},
  beforeDestroy() {},
  methods: {
    resetData() {
      this.BOMList = [];
    },
    searchTable(val) {
      if(val.trim()==""){
        this.BOMList = JSON.parse(JSON.stringify(this.BOMList2));
        return;
      }
      var arr = JSON.parse(JSON.stringify(this.BOMList2));

      var res = [];
      arr.forEach(element => {
        if(element.serialsCode==val||element.inspectionEntryName==val){
          res.push(element);
        }
      });
      this.BOMList = res;
    },
    async init() {
      this.BOMList2 = [];
      this.processFlowVersion.procedureList.forEach(element => {
        var inspectEntryList = element.inspectEntryList;
        if(inspectEntryList!=null){
          inspectEntryList.forEach(element2 => {
            this.$set(element2,"serialsCode",element.serialsCode)
            this.$set(element2,"procedureName",element.procedureName)
            this.BOMList2.push(element2)
          });
        }
      });
      this.BOMList = JSON.parse(JSON.stringify(this.BOMList2));
      // }
    },
    modify(row) {
      console.log(row);
      this.visible = true;
    },
    del(row) {
      this.$confirm("您确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        //   const res = await api.deleteBomClass({ id: row.id })
        //   if (res) {
        //     this.$message.success('删除成功')
        //     await this.getMaterialsClassList()
        //   }
      });
    },
    downloadFile(urlP, name) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = urlP;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
  <style lang="scss">
.err-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  .err-img {
    width: 150px;
    height: auto;
    margin-right: 69px;
  }
  .err-msg {
    margin-top: -30px;
    font-size: 14px;
    line-height: 20px;
    .title {
      font-weight: 600;
      color: #393d60;
    }
    .detail {
      color: #9597ae;
    }
  }
}
.flow-wrapper {
  .m-radio-group {
    width:auto;
    float: right;
    margin: 10px 0;
    .el-radio-button {
      min-width: 60px !important;
      height: 40px;
    }
    .el-radio-button__inner {
      padding: 4px 8px !important;
      min-width: 40px !important;
    }
  }

  .m-table {
    top: 20px;
  }
}
</style>
