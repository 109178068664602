import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/authority/private/role/'

export default {
  // 获取用户-不分页
  getRoleList(data) {
    return request.get(baseUrl + 'getRoleList', data)
  },
  // 获取用户-分页
  getRoleListPage(data) {
    return request.get(baseUrl + 'getRoleListPage', data)
  },
  // 新增用户
  addRole(data) {
    return request.post(baseUrl + 'addRole', data)
  },
  // 新增用户
  copyRole(data) {
    return request.post(baseUrl + 'addRole', data)
  },
  // 修改用户
  modifyRole(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除用户
  delRole(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  getRoleListByUserId(userId) {
    return request.get(baseUrl + 'getRoleListByUserId', { userId })
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxRoleCode', params)
  }
}
