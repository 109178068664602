import Vue from 'vue'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import './utils/consoleSelf'
import ElementUI from 'element-ui'
import './styles/element-variables.scss'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/permission' // permission control
import '@/plugins/WSPlayer/player.css'
import '@/plugins/WSPlayer/window.division.css'
import '@/plugins/WSPlayer/PlaySDKInterface.js'
import '@/plugins/WSPlayer/WSPlayer.js'

import Directives from '@/directive'
Vue.use(Directives)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import vGanttChart from '@/components/ganttChart/index'
Vue.use(vGanttChart)

import VueTyperPlugin from 'vue-typer'
Vue.use(VueTyperPlugin)
// import VueTypedJs from 'vue-typed-js'
// Vue.use(VueTypedJs)

// 打印模板设计
// import '@/components/printDesigner/libs/lodop/LodopFuncs.js'
import lodop from '@/components/printDesigner/libs/lodop/index.js'
import vptd from '@/components/printDesigner/store/index'
import widgets from '@/components/printDesigner/components/widgets/index'
Vue.use(widgets)
Vue.prototype.$lodop = lodop
Vue.prototype.$vptd = vptd

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI)

import mColor from '@/styles/m-variables.scss'
Vue.prototype.mColor = mColor

Vue.config.productionTip = false

/** write by luomingzhi */
import MComponents from '@/components/MCustom'
import _ from 'lodash'
import Print from '@/utils/print'
import { isInsertNode } from '@/utils'
Vue.prototype.permission = isInsertNode
Vue.use(MComponents)
Vue.use(Print)
Vue.prototype._ = _
import elCascaderMulti from 'el-cascader-multi'
Vue.use(elCascaderMulti)
import VeLine from 'v-charts/lib/line.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import VePie from 'v-charts/lib/pie.common'
import VeBar from 'v-charts/lib/bar.common'
Vue.component(VeLine.name, VeLine)
Vue.component(VeHistogram.name, VeHistogram)
Vue.component(VePie.name, VePie)
Vue.component(VeBar.name, VeBar)
import '@/plugins/datav'
/** write by luomingzhi */
// 富文本插件
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'

import 'quill/dist/quill.snow.css'

import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)
Vue.Cancel = []
import htmlToPDF from '@/utils/plug/htmlToPdf'
Vue.use(htmlToPDF)
import SelectRemoteSearch from '@/components/SelectRemoteSearch/index.vue'
Vue.component('SelectRemoteSearch', SelectRemoteSearch)
//根据请求地址判断是否需要添加meta 如果不是https那么去掉 meta头
// if(process.env.VUE_APP_BASE_API.includes("http://")){
//   document.querySelector("#metaForHttps").remove()
// }
const vue = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

export default vue
