/** write by luomingzhi */

export const sColumns = [
  // {
  //   prop: 'id',
  //   label: 'ID',
  //   sortable: true,
  //   hiddenSearch: true,
  //   width: 80
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
 
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  
  {
    prop: 'mainUnitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'upperLimit',
  //   label: '库存上限',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  // {
  //   prop: 'lowerLimit',
  //   label: '库存下限',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  // {
  //   prop: 'defaultRepositoryName',
  //   label: '默认仓库',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    prop: 'isEnabled',
    label: '物料状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled'
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export const columns = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  // {
  //   prop: 'id',
  //   label: 'ID',
  //   sortable: true,
  //   hiddenSearch: true,
  //   width: 80
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  
  {
    prop: 'mainUnitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'upperLimit',
  //   label: '库存上限',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  // {
  //   prop: 'lowerLimit',
  //   label: '库存下限',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  // {
  //   prop: 'defaultRepositoryName',
  //   label: '默认仓库',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    prop: 'isEnabled',
    label: '物料状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled'
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export const ivColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsQuality',
    label: '材质',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '库存单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'currentQuantity',
    label: '当前存货',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'stayInWarehouseQuantity',
    label: '待入库数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'stayOutWarehouseQuantity',
    label: '待出库数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'containerQuantity',
    label: '箱数',
    width: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'scatterQuantity',
    label: '散数',
    width: 100,
    hiddenSearch: true,
    sortable: false
  }
]
