<template>
  <div class="m-table">
    <div v-if="columnsSetting && showColumnsSetting()" class="columns-setting">
      <el-popover
        ref="popover"
        v-model="showPopover"
        :width="207"
        :offset="20"
        @show="popoverShowEvent"
      >
        <div class="popover-content-top">
          <el-button @click="cancel">取消</el-button>
          <el-button @click="saveColumns">确定</el-button>
        </div>
        <div class="popover-content-center">
          <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
          <el-button class="invert" type="text" @click="invertSelectColumns">
            <img src="@/assets/table_filter_images/fanxuan@2x.png" alt="反选" width="13px">
            <span>反选</span>
          </el-button>
        </div>
        <div class="popover-content-footer" :style="{maxHeight: popoverContentMaxHeight + 'px'}">
          <el-checkbox-group v-model="checkedList" class="check-wrapper">
            <draggable
              v-model="settingColumns"
              v-bind="dragOptions"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <el-checkbox v-for="item in settingColumns" :key="item.key" :label="item.prop" >
                <div style="display:flex;justify-content:space-between" >
               <div>{{ item.label }}</div>
               <div>
                <img v-show="item.fixeds && checkedList.includes(item.prop)" @click.stop.prevent="setFixed(item, false)" src="@/assets/table_filter_images/fixed-1.png">
                <img v-show="!item.fixeds && checkedList.includes(item.prop)" @click.stop.prevent="setFixed(item, 'left')" src="@/assets/table_filter_images/fixed-2.png">
              </div>
              </div>
              </el-checkbox>
            </draggable>
          </el-checkbox-group>
        </div>
      </el-popover>
      <el-popover
        popper-class="pop-popover"
        placement="bottom"
        trigger="hover">
        <div class="line-height-setting">
          <div class="setting-item" :class="tableRowHeight === '30' ? 'actived' : ''" @click="changeLineHeight('30')">
            <img class="setting" src="@/assets/table_filter_images/line-height-compact.png" alt="设置">
            <span>紧凑</span>
          </div>
          <div class="setting-item" :class="tableRowHeight === '40' ? 'actived' : ''" @click="changeLineHeight('40')">
            <img class="setting" src="@/assets/table_filter_images/line-height-comfort.png" alt="设置">
            <span>舒适</span>
          </div>
          <div class="setting-item" :class="tableRowHeight === '50' ? 'actived' : ''" @click="changeLineHeight('50')">
            <img class="setting" src="@/assets/table_filter_images/line-height-spacious.png" alt="设置">
            <span>宽敞</span>
          </div>
        </div>
        <el-button slot="reference" type="text" style="padding: 0">
          <img class="line-height" src="@/assets/table_filter_images/line-height.png" alt="行高">
        </el-button>
      </el-popover>

      <el-button ref="columnsSettingBtn" v-popover:popover type="text" style="padding: 0">
        <img class="setting" src="@/assets/table_filter_images/setting.png" alt="设置">
      </el-button>
      <el-button ref="columnsSettingBtn" type="text" style="padding: 0" @click="refreshPage()">
        <img class="refresh" src="@/assets/table_filter_images/refresh.png" alt="刷新">
      </el-button>
    </div>
    <template v-if="showTable">
      <el-table
        ref="mTable"
        v-loading="loading"
        :class="['ellipsis-table', showAppend ? 'hidden-empty' : '']"
        class="ellipsis-table"
        :data="tableData"
        :row-key="setRowKey"
        :height="height"
        border
        v-bind="$attrs"
        v-on="$listeners"
        @filter-change="filterChange"
        @sort-change="sortChange"
        @header-dragend="headerChange"
        :row-style="{height: `${tableRowHeight}px`}"
      >
        <template v-for="(item, index) in newColumns">
          <el-table-column
            v-if="item.type === 'selection' && selectShowFalse"
            :key="index"
            :resizable="false"
            column-key="selection"
            type="selection"
            width="40"
            v-bind="item"
            :selectable="selectable"
          />
          <el-table-column
            v-else-if="item.type !== 'selection'"
            :key="index"
            :column-key="item.prop"
            :sortable="item.prop === 'index' ? false : 'sortable' in item ? item.sortable : 'custom'"
            :min-width="xScroll ? item.slotName !== 'action' ? item.minWidth || 180 : item.width || 180 : ''"
            :resizable="item.slotName !== 'action'"
            v-bind="item"
            :width="item.slotName !== 'action' ? item.width : ''"
          >
            <template slot-scope="{ row, column, $index }">
              <slot v-if="item.slotName" :name="item.slotName" v-bind="{ row, column, $index, columnIndex: index }" />
              <span v-else>{{ [undefined,null,''].includes(row[column.property])?'-':row[column.property] }}</span>
            </template>
            <template slot="header" slot-scope="{ row, column }">
              <span v-if="item.require" style="color:red;marginRight:5px">* </span>
              <template v-if="item.headerTooltip">
                <el-tooltip  effect="dark" :content=" '合计：'+item.headerTotalcount+''" placement="top">
                <span @click="$emit('headerClick', index)">{{ column.label }}</span>
                </el-tooltip>
              </template>
              <template v-if="!item.headerTooltip">
                <span @click="$emit('headerClick', index)">{{ column.label }}</span>
              </template>

              <slot v-if="item.headerSlotName" :name="item.headerSlotName" v-bind="{ row, column, columnIndex: index }" :originColumn="item" />
              <template v-else>
                <el-popover
                  v-if="!column.filters && !item.hiddenSearch"
                  placement="bottom"
                  popper-class="popover-search"
                  width="200"
                  trigger="click"
                >
                  <div class="popover-content" style="padding-bottom: 10px">
                    <el-input
                      v-model="searchValue[column.property]"
                      size="medium"
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                    />
                  </div>
                  <div class="popover-search-container el-table-filter__bottom">
                    <button
                      :disabled="!searchValue[column.property]"
                      :class="!searchValue[column.property] ? 'is-disabled' : ''"
                      @click="search(column.property)"
                    >
                      搜索
                    </button>
                    <button @click="resetSearch(column.property)">重置</button>
                  </div>
                  <i slot="reference" class="popover-reference el-icon-search" @click="popoverStop" />
                </el-popover>
              </template>
            </template>

          </el-table-column>

        </template>
        <template slot="append" v-if="showAppend">
          <div style="padding-top:8px;">
          <img src="@/assets/plans/add.png" class="num-img" @click="addTaskNode">
        </div>
    </template>
    <template slot="empty" v-if="showAppend">
        <span></span>
    </template>
      </el-table>
      <div v-if="showPage" class="page-container">
        <el-pagination
          :current-page="page.currentPage"
          :page-sizes="page.pageSizes"
          :page-size="page.pageSize"
          :layout="layoutStyle"
          :pager-count="pagerCount"
          :total="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { getUUid, setTreeData } from '@/utils'
import $ from 'jquery'
import userApi from '@/api/sets/user/user'
import draggable from 'vuedraggable'
import Sortable from 'sortablejs'

export default {
  name: 'MTable',
  components: { draggable },
  props: {
    data: {
      type: [Array, Boolean],
      default() {
        return false
      }
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    setDataMethod: {
      type: Function,
      default() {
        return () => { }
      }
    },
    isTreeData: {
      type: Boolean,
      default: false
    },
    treeDataParentId: {
      type: [String, Number],
      default: -1
    },
    showPage: {
      type: Boolean,
      default: true
    },
    columnsKey: {
      type: [String, Boolean],
      default: false
    },
    columnsSetting: {
      type: Boolean,
      default: true
    },
    xScroll: {
      type: Boolean,
      default: true
    },
    onlyKey: {
      type: String,
      default: 'id'
    },
    customPageSize: {
      type: Number,
      default: 0
    },
    immediateGetData: {
      type: Boolean,
      default: true
    },
    canRowSortable: {
      type: Boolean,
      default: false
    },
    selectShowFalse:{
      type:Boolean,
      default:true
    },
    useStation:{
      type:String,
      default:''
    },
    mustShowColumnsSetting: { // 最高优先级控制显示
      type: Boolean,
      default: false
    },
    layoutStyle:{
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pagerCount:{
      type: Number,
      default: 7
    },
    showAppend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showTable: true,
      newColumns: [],
      tableData: [],
      page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 50, 100, 150],
        total: 0,
        currentPage: 1
      },
      order: '',
      searchValue: {},
      searchFormatValue: {},
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 200,
      settingColumns: [],
      copySettingColumns: [],
      checkedList: [],
      isDragging: false,
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      defaultPageSize: 20,
      totalCount:{
        quantity:0,
        reportWorkQuantity:0
      }
    }
  },
  computed: {
    height() {
      // eslint-disable-next-line prefer-const
      let { height } = this.$attrs
      const maxHeight = this.$attrs['max-height'] || this.$attrs.maxHeight
      if (maxHeight) return null
      if (!height) height = this.$store.state.app.contentHeight - 56 - 44 - 12 - 37 - 40 - 25
      return this.showPage ? height - 44 : height - 5
    },
    pageSize() {
      return this.$store.state.app.pageSize
    },
    tableRowHeight() {
      return this.$store.state.app.tableRowHeight
    }
  },
  watch: {
    'pageSize': {
      handler: function(val) {
        const cloneVal = this._.cloneDeep(val)
        if (!this.customPageSize) {
          this.page.pageSize = cloneVal.default || 20
          this.page.pageSizes = cloneVal.list && cloneVal.list.length ? cloneVal.list : [10, 20, 50, 100, 150]
        } else {
          this.page.pageSize = this.customPageSize
        }
        this.getCheckedColumns()
        this.$nextTick(() => {
          if (this.data) {
            this.tableData = this.data
            this.getTotalCount(this.tableData)
          } else {
            this.immediateGetData && this.setTableData()
          }
          this.calcPopoverContentHeight()
        })
      },
      immediate: true
    },
    data(val) {
      // if (val) this.loading = false
      this.tableData = this.data || []
      this.getTotalCount(this.tableData)
    },
    columns() {
      this.resetPagination()
      this.getCheckedColumns()
      this.$nextTick(() => {
        this.$refs.mTable.doLayout()
      })
    },
    newColumns() {
      this.$nextTick(() => {
        this.$refs.mTable.doLayout()
      })
    }
  },
  mounted() {
    // 阻止火狐拖拽新建新页面
    document.body.addEventListener('drop', (event) => {
      event.preventDefault()
      event.stopPropagation()
    }, false)
    // 排序
    this.initSortableList()
  },
  methods: {
    async addTaskNode() {
    await this.$store.dispatch('tagsView/addCachedView', this.$route)
      localStorage.setItem('taskNodeList', JSON.stringify(this.data));
      this.$router.push({ name: 'AddTaskNodes' ,query:{type:'addParent'} })
},

    setFixed(item,value) {
      this.$set(item, 'fixeds', value)
      this.$forceUpdate()
    },
    // 更改table行高
    changeLineHeight(size) {
      this.$store.dispatch('app/updateTableRowHeight', size)
    },
    // 刷新数据
    refreshPage() {
      this.$store.dispatch('tagsView/delCachedView', this.$route)
      this.$router.replace({
        path: '/refresh',
        params: this.$route.params,
        query: this.$route.query
      })
    },
    // 更新排序
    initSortableList() {
      if (!this.canRowSortable) return
      const el = this.$refs.mTable.$el.querySelector('.el-table__body-wrapper tbody')
      // 设置配置
      const _this = this
      Sortable.create(el, {
        animation: 150,
        sort: true,
        draggable: '.el-table__row', // 设置可拖拽行的类名(el-table自带的类名)
        forceFallback: true,
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
          _this.$emit('onSorted', _this.tableData, newIndex, oldIndex)
        }
      })
    },
    // 初始化分页
    resetPagination() {
      this.page.total = 0
      this.page.currentPage = 1
      this.page.pageSize = this.pageSize.default || this.customPageSize || 20
    },
    popoverShowEvent() {
      this.settingColumns = this._.clone(this.copySettingColumns).map(
        item=>{
          return {
            ...item,
            fixeds:item.fixed
          }
        }
      )
      this.checkedList = this.newColumns
        .filter(item => item.slotName !== 'action' && item.type !== 'selection')
        .map(item => item.prop)
    },
    async getCheckedColumns() {
      // console.log("当前路由信息",this.$route,this.$route.name)
      const columns = this._.cloneDeep(this.columns)
      // 如果没有表头筛选功能直接赋值
      if (!this.columnsSetting || !this.showColumnsSetting()) {
        this.newColumns = columns
        return false
      }
      // 如果有表头筛选功能从后台获取或直接赋值
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const { fields, id } = userInfo
      const currentFields = fields && JSON.parse(fields)[this.columnsKey || this.$route.name]
      this.settingColumns = columns
      this.copySettingColumns = this._.clone(this.settingColumns)
        .filter(item => item.slotName !== 'action' && item.type !== 'selection')
      if (currentFields) {
        const checkedFields = currentFields.filter(item => item.checked)
        const checkedKeys = checkedFields.map(item => item.key)
        const columnsProps = currentFields.map(item => item.key)
        this.newColumns = columns.filter(item => {
          const checkIndex = checkedKeys.indexOf(item.prop)
          if (checkedKeys.indexOf(item.prop) !== -1) {
            item.index = checkedFields[checkIndex].index
            if (checkedFields[checkIndex].width) {
              item.minWidth = checkedFields[checkIndex].width
              item.width = checkedFields[checkIndex].width
            }
            if (checkedFields[checkIndex].fixed !== undefined) {
              item.fixed = checkedFields[checkIndex].fixed
              item.fixeds = checkedFields[checkIndex].fixed
            }
          } else if (columnsProps.indexOf(item.prop) !== -1) {
            const current = currentFields[columnsProps.indexOf(item.prop)]
            item.index = current.index
            if (current.width) {
              item.minWidth = current.width
              item.width = current.width
            }
            if (current.fixed) {
              item.fixed = current.fixed
              item.fixeds = current.fixed
            }
          }
          if (item.type === 'selection') {
            item.index = 0
          }
          if (item.slotName === 'action') {
            item.index = columns.length
          }
          return checkIndex !== -1 ||
            item.slotName === 'action' ||
            item.type === 'selection'
        })
        this.newColumns.sort((a, b) => a.index - b.index)
        // console.log('接口获取的处理之后的表头信息:>> ',this.newColumns);
        this.$nextTick(() => {
          const showColumnsInfo = this.newColumns.reduce((a,b)=>{
            if(b.label){
              return a + ' ' + b.label
            }else{
              return a;
            }
          },'');
          if(document.querySelector(".el-breadcrumb.app-breadcrumb").lastChild.lastChild){
            const routeName = document.querySelector(".el-breadcrumb.app-breadcrumb").lastChild.lastChild.innerText;
            // console.log("后端传回来的表格字段展示顺序:>> " + routeName + ":" + this.$route.name,showColumnsInfo)
          }else{
            // console.log("后端传回来的表格字段展示顺序:>> " + ":" + this.$route.name,showColumnsInfo)
          }
        })
        this.settingColumns = columns
          .filter(item => item.slotName !== 'action' && item.type !== 'selection')
          .sort((a, b) => a.index - b.index)
        this.copySettingColumns = this._.clone(this.settingColumns)
        this.checkedList = checkedKeys
        return false
      }
      this.newColumns = columns
      this.checkedList = this.settingColumns.map(item => item.prop)
      const newFields = fields && JSON.parse(fields) || {}
      newFields[this.columnsKey || this.$route.name] = this.settingColumns.map((item, index) => ({
        key: item.prop,
        checked: true,
        index
      }))
      userInfo.fields = JSON.stringify(newFields)
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      await userApi.updateFields({
        id,
        fields: JSON.stringify(newFields)
      })
    },
    showColumnsSetting() {
      if (this.mustShowColumnsSetting) return true
      const parentChildNames = this.$parent.$children.map(item => item.$options.name)
      const functionHeaderIndex = parentChildNames.findIndex(item => item === 'FunctionHeader')
      const selfIndex = parentChildNames.findIndex(item => item === 'MTable')
      return (functionHeaderIndex !== -1 && (selfIndex - functionHeaderIndex) === 1) || this.columnsKey
    },
    calcPopoverContentHeight() {
      if (!this.$refs.columnsSettingBtn) return false
      // 根据表格列按钮距底部高度计算popover的最大内容高度
      const position = $(this.$refs.columnsSettingBtn.$el).offset()
      const contentHeight = document.documentElement.offsetHeight
      this.popoverContentMaxHeight = contentHeight - position.top - 180
    },
    popoverStop() {
      window.event.stopPropagation()
    },
    // 设置row-key
    setRowKey(row) {
      return row[this.onlyKey] || row.childLevelId|| row.id || row.uuid || row.userName
    },
    // 设置tableData
    setTableData(reset) {
      this.loading = true
      const search = []
      if (reset) this.resetPagination()
      Object.keys(this.searchFormatValue).forEach(item => {
        search.push(this.searchFormatValue[item])
      })
      this.setDataMethod(
        {
          page: this.page,
          search,
          order: this.order
        },
        async(res) => {
          if (res) {
            if (this.isTreeData) {
              const treeData = setTreeData(res.content, this.treeDataParentId)
              this.tableData = treeData.length ? treeData : res.content
            } else {
              this.tableData = res.content
            }
            this.page.total = res.total
            this.getTotalCount(this.tableData)
            this.loading = false
          } else {
            this.loading = false
          }
        }
      )
    },
    // 筛选
    filterChange(filters) {
      const filtersKeys = Object.keys(filters)
      filtersKeys.forEach(item => {
        if (filters[item].length) {
          this.searchFormatValue[item] = {
            id: getUUid(),
            fieldName: item,
            fieldType: typeof filters[item][0],
            fieldValue: filters[item].join('^'),
            operator: 'in'
          }
        } else {
          delete this.searchFormatValue[item]
        }
      })
      this.setTableData(true)
    },
    // 排序
    sortChange(sort) {
      if (sort.column.sortable !== 'custom') return
      if (sort.order) {
        this.order = sort.order === 'ascending' ? `+${sort.prop}` : `-${sort.prop}`
      } else {
        this.order = ''
      }
      this.setTableData()
    },
    // 表头搜索
    search() {
      const searchKeys = Object.keys(this.searchValue)
      searchKeys.forEach(item => {
        if (this.searchValue[item]) {
          this.searchFormatValue[item] = {
            id: getUUid(),
            fieldName: item,
            fieldType: 'string',
            fieldValue: this.searchValue[item],
            operator: 'like'
          }
        } else {
          delete this.searchFormatValue[item]
        }
      })
      this.setTableData(true)
    },
    // 表头搜索重置
    resetSearch(key) {
      this.searchValue[key] = ''
      this.search()
    },
    // 每页数据量变化
    handleSizeChange(val) {
      this.page.pageSize = val
      this.setTableData()
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.setTableData()
    },
    // table Ref
    tableRefs() {
      return this.$refs.mTable
    },
    cancel() {
      this.showPopover = false
      this.allChecked = false
      setTimeout(this.resetSettingFields, 500)
    },
    async saveColumns() {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const { id, fields } = userInfo
      const newFields = fields && JSON.parse(fields) || {}
      newFields[this.columnsKey || this.$route.name] = this.settingColumns.map((item, index) => ({
        key: item.prop,
        checked: this.checkedList.indexOf(item.prop) !== -1,
        width: item.width,
        fixed:item.fixeds,
        index
      }))
      userInfo.fields = JSON.stringify(newFields)
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.showPopover = false
      this.allChecked = false
      await this.getCheckedColumns()
      this.refreshTable()
      await userApi.updateFields({
        id,
        fields: JSON.stringify(newFields)
      })
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map(item => item.prop) : []
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter(item => this.checkedList.indexOf(item.prop) === -1)
        .map(item => item.prop)
    },
    resetSettingFields() {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const { fields } = userInfo
      const currentFields = fields && JSON.parse(fields)[this.columnsKey || this.$route.name]
      if (currentFields) {
        this.checkedList = this.settingColumns
          .filter(item => currentFields.indexOf(item.prop) !== -1)
          .map(item => item.prop)
      }
    },
    refreshTable() {
      this.showTable = false
      this.$nextTick(() => { this.showTable = true })
    },
    async headerChange(newW, oldW, column) {
      if (newW !== oldW) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        const { id, fields } = userInfo
        const newFields = fields && JSON.parse(fields) || {}
        const index = newFields[this.columnsKey || this.$route.name].findIndex(item => item.key === column.property)
        newFields[this.columnsKey || this.$route.name][index].width = newW
        // console.log(newFields[this.columnsKey || this.$route.name])
        userInfo.fields = JSON.stringify(newFields)
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        await userApi.updateFields({
          id,
          fields: JSON.stringify(newFields)
        })
      }
    },
    selectable(row) {
      if (row.selectable) {
        return false
      }
      return true
    },
    getTotalCount(dataList){
      // console.log(this.useStation)
      // if(this.useStation !=='workList') return
      this.modifyColumns(this.newColumns,'reportWorkQuantity','reportWorkQuantity',dataList)
      this.modifyColumns(this.newColumns,'workOrderQuantity','workOrderQuantity',dataList)
    },
    modifyColumns(newColumns,itemNm,itemValue,dataList){
      let targetItem = newColumns.find(item => item.prop === itemNm);
      if (targetItem) {
        targetItem.headerTotalcount = dataList.reduce((sum, item) => sum + ((item[itemValue]-0) || 0), 0);
      }
    }
  }
}
</script>

<style lang="scss">
.popover-content {
  position: relative;
  &:after {
    content: "";
    width: calc(100% + 24px);
    height: 1px;
    bottom: 0;
    left: -12px;
    background: #ebeef5;
    position: absolute;
  }
}
.popover-search-container {
  position: relative;
  top: 7px;
  padding: 0 !important;
  border: none !important;
  span {
    padding: 0 3px;
    color: #606266;
  }
}
.m-table {
  tr {
    td {
      // background: #fff;
      button {
        padding: 0;
      }
    }
  }
  i.popover-reference,
  .el-table__column-filter-trigger {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  i.popover-reference {
    z-index: 1;
  }
  .el-table th {
    background: #f6f7f9;
  }
  .el-table--small th,
  .el-table--small td {
    padding: 0;
  }

  .el-table th {
    padding: 4px 0;
  }

  .el-table td {
    padding: 0;
  }
  .el-table__expanded-cell{
    padding-left:50px !important
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #f6f7f9;
  }
  .el-table .caret-wrapper {
    height: 28px;
  }
  .el-table .sort-caret.ascending {
    top: 2px;
  }
  .el-table .sort-caret.descending {
    bottom: 4px;
  }
  .el-input--mini .el-input__inner {
    height: 28px;
  }
}
</style>
<style scoped lang="scss">
.m-table {
  position: relative;
  .num-img {
        width: 14px;
        height: 14px;
        display: inline-block;
        cursor: pointer;
        margin-left: 8px;
      }
  ::v-deep {
    .ellipsis-table {
      .cell{
        padding-left:8px;
        padding-right:8px;
      }
      td:not(:last-child),
      th {
        .cell > button,
        .cell > div > button,
        .cell > span > button {
          width: 100%;
        }
        .cell > div > a > button,
        .cell > div > span > button {
          width: 100%;
          text-align: left;
        }
        .cell,
        .cell > button,
        .cell > div > button,
        .cell > div,
        .cell > div > a > button,
        .cell .tooltip,
        .cell > span > button,
        .cell > div > span > button,
        .cell > div > div:not(.el-image) {
          box-sizing: border-box;
          text-align: left;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .el-table__append-wrapper{
        position:relative;
        z-index:998
      }
    }
    .hidden-empty{
      .el-table__empty-block{
        display:none;
      }
    }
  }
}
.page-container {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  ::v-deep {
    .el-pager li {
      min-width: 26.5px;
    }
  }
}

.line-height-setting {
  .setting-item {
    padding: 5px 12px;
    display: flex;
    gap: 0 10px;
    align-items: center;
    cursor: pointer;

    &.actived {
      background-color: #E6EAF8;
    }

    img {
      width: 13px;
    }
  }
}
.columns-setting {
  position: absolute;
  right: 0;
  top: -35px;
  cursor: pointer;

  .el-button {
    margin-left: 12px;
  }

  .line-height {
    width: 14px;
  }
  .setting {
    width: 15px;
  }
  .refresh {
    width: 14px;
  }
}
.popover-content-top {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  button {
    padding: 8px 20px;
    border-radius: 4px;
    border-color: #dedeee;
    color: #8a8ca5;
    &:last-child {
      border-color: #607fff;
      color: #607fff;
    }
  }
}
.popover-content-center {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: #f8f8fb;
  height: 25px;
  button.invert {
    span {
      color: #8a8ca5;
    }
    img {
      position: relative;
      top: 2px;
      right: 10px;
    }
  }
}
.popover-content-footer {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  max-height: 380px;
  overflow-y: auto;
  .el-checkbox-group {
    overflow: hidden;
    .el-checkbox {
      width: 100%;
      padding: 8px 8px 8px 28px;
      cursor: pointer;
      &:hover {
        background: #ecf0f4;
      }
      ::v-deep {
        .el-checkbox__label {
            width:90%;
        }
      }
    }
  }
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
