<template>
  <!-- <applyMarket/> -->
  <div v-if="!this.$route.meta.hiddenSiderbar" class="layout-container">
    <LockScreen />
    <div class="body-container">
      <div id="layoutLeft" class="left" v-show="!fullScreen">
        <MSideBar />
      </div>
      <div class="right" :style="{width: rightWidth + 'px'}">
        <div class="header">
          <Header />
        </div>
        <div class="content">
          <AppMain />
        </div>
      </div>
    </div>
    <Navigation />
  </div>
  <div v-else-if="this.$route.meta.activeMenu=='applyMarket'" class="layout-container">
    <applyMarket />
  </div>
  <div v-else-if="this.$route.meta.activeMenu=='applyDetails'" class="layout-container">
    <applyDetails />
  </div>
</template>

<script>
/** write by luomingzhi */
import { AppMain, Header, MSideBar, MTagView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import LockScreen from '@/components/LockScreen/index'
import applyMarket from '@/views/apply/index'
import applyDetails from '@/views/apply/applyDetails'
import Navigation from '@/components/Navigation/navigation'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Header,
    MSideBar,
    MTagView,
    LockScreen,
    applyMarket,
    applyDetails,
    Navigation
  },
  mixins: [ResizeMixin],
  data() {
    return {
    }
  },
  computed: {
    opened() {
      return this.$store.state.app.sidebar.opened
    },
    secondOpened() {
      return this.$store.state.app.sidebar.secondOpened
    },
    fullScreen() {
      return this.$store.state.app.sidebar.fullScreen
    },
    contentWidth() {
      return this.$store.state.app.contentWidth
    },
    rightWidth() {
      if (this.fullScreen) {
        return this.contentWidth
      }
      if (this.opened) {
        if (this.secondOpened) {
          return this.contentWidth - 283
        } else {
          return this.contentWidth - 138
        }
      }
      return this.contentWidth - 38
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  position: relative;
  .body-container {
    display: inline-flex;
    height: calc(100vh);
    transition: all .5s;
    .left {
      z-index: 999;
    }
    .right {
      width: 100%;
    }
  }
  .right {
    transition: all .5s;
  }
}
</style>
